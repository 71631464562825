const getUserFields = (user, token) => {
    const userToUpdate = {
        _id: user._id,
        username: user.username,
        email: user.email,
        profilePictureUrl: user.profilePictureUrl,
        isImportCompany: user.isImportCompany,
        isImportAdmin: user.isImportAdmin,
        isSuperAdmin: user.isSuperAdmin,
        subscription: user.subscription,
        accessToken: token,
        importCompanyOptions: user.importCompanyOptions,
        //
        importCompanyName: user.importCompanyName,
        importCompanyDescription: user.importCompanyDescription,
        importCompanyPercent: user.importCompanyPercent,
        importCompanyFastDelivery: user.importCompanyFastDelivery,
        importCompanyMinPercent: user.importCompanyMinPercent,
        importCompanyMaxPercent: user.importCompanyMaxPercent,
        isManualImportCost: user.isManualImportCost,
        //
        name: user.name,
        lastName: user.lastName,
        fullName: user.name + ' ' + user.lastName,
        wallet: user.wallet,
        productsSoldCount: user.productsSoldCount,
        notificationsWeb: user.notificationsWeb,
        notificationsEmail: user.notificationsEmail,
        phone: user.phone,
        userPromotedCount: user.userPromotedCount,
        productsSoldCount: user.productsSoldCount,
        deliveryAddresses: user.deliveryAddresses,
        deliveryCosts: user.deliveryCosts,

        couponCode: user.couponCode,
        discountCoupon: user.discountCoupon
    }
    return userToUpdate
}

const getDeliveryCalendarInWeeks = (valueInHours) => {
    let textValue = `${valueInHours} Hora${parseInt(valueInHours) === 1? "": "s"}`
    if(Math.trunc(valueInHours) >= 24){
        if(Math.trunc(valueInHours/24) < 7){
            textValue = `${Math.trunc(valueInHours/24)} Día${Math.trunc(valueInHours/24)===1? "" : "s"}`
            if(valueInHours%24 > 0){
                textValue += `, ${valueInHours%24} Hora${valueInHours%24===1? "" : "s"}`
            } 
        }
        else{
            textValue = `${Math.trunc(valueInHours/24/7)} Semana${Math.trunc(valueInHours/24/7) === 1? "" : "s"}`
            if(Math.trunc(valueInHours/24)%7 > 0){
                textValue += `, ${Math.trunc(valueInHours/24)%7} Día${Math.trunc(valueInHours/24)%7===1? "" : "s"}`
            }
            if(valueInHours%24 > 0){
                textValue += `, ${valueInHours%24} Hora${valueInHours%24===1? "" : "s"}`
            }
        }
    }
    return textValue
}

const getUserLoggedId = () => {
    const isUserLogged = window.localStorage.getItem('user')
    if(!isUserLogged) return null
    const userId = JSON.parse(isUserLogged).idToken
    return userId
}

const getUserLoggedToken = () => {
    const isUserLogged = window.localStorage.getItem('user')
    if(!isUserLogged) return null
    const accessToken = JSON.parse(isUserLogged).accessToken
    return accessToken
}

const getTimeString = (value) => {
    if(value < 10) return `0${value}`
    else return `${value}`
}

export default getUserFields
export { getDeliveryCalendarInWeeks, getTimeString, getUserLoggedId, getUserLoggedToken }