import { UPDATE_ORDERS, UPDATE_SEARCH_ORDER_PARAMS } from "../actions/ordersAction";

const default_cart = {
    currentOrders: {
        orders: [],
        page: 0,
    },
    searchOrderParameters:{
        page: 0,
        limit: 20,
        statusToSearch: 'Todos',
        guideHouseToSearch: '',
        userId: '',
        userFullName: '',
        orderId: '',
        orderNumber: ''
    }
}

function orders_store(state = default_cart, action){
    switch(action.type) {
        case UPDATE_ORDERS: {
            return{
                ...state,
                currentOrders: {
                    orders: action.payload.orders,
                    page: action.payload.page
                }
            }
        }
        case UPDATE_SEARCH_ORDER_PARAMS: {
            return{
                ...state,
                searchOrderParameters: action.payload
            }
        }
        default: return state;
    }
}

export default orders_store