import { sendingCostValue } from "../config";
import { getTimeString } from "./userTools";

const reduceDecimalValue = (number, decimalValue, noExactDecimal) => {
    if(number === 0) {
        if(!noExactDecimal){
            return 0.00
        }
        else{
            return 0
        }
    }
    const dValue = decimalValue !== undefined? decimalValue : 2
    if(!number || number === undefined || number === null) {
        if(!noExactDecimal){
            return 0.00
        }
        else{
            return 0
        }
    }
    if(Math.floor(number.valueOf()) === number.valueOf()) {
        if(!noExactDecimal){
            return number.toFixed(dValue)
        }
        else{
            return number.toFixed(dValue)
        }
    }
    let hasMoreDecimal = 0
    try{
        hasMoreDecimal = number.toString().split(".")[1].length || 0
    }
    catch(e){
        console.log(e)
    }
    if(hasMoreDecimal > dValue){
        return number.toFixed(dValue)
    }
    if(!noExactDecimal){
        return parseFloat(number).toFixed(dValue)
    }
    else{
        return parseFloat(number).toFixed(dValue)
    }
}

const calcSendingCost = (product, sendingCost) => {
    let currentSendingCostValue = sendingCost? sendingCost : sendingCostValue 
    if(product.isManualImportCost){
        if(product.importCost){
            return product.importCost
        }
        else{
            return 0
        }
    }
    let weightCost = 0
    let otherCost = 0
    otherCost = parseInt(product.large) * parseInt(product.height) * parseInt(product.width) / 6000
    weightCost = (parseFloat(product.weight))
    if(weightCost >= otherCost || product.shippingBySea){
        return weightCost * currentSendingCostValue
    }
    return otherCost * currentSendingCostValue
}

const getTotalPrice = (product) => {
    let total = product.price
    const sendingCost = calcSendingCost(product)
    if(sendingCost) total += sendingCost
    return reduceDecimalValue(total, 2) 
}

const getDeliveryTime = (product) => {
    if(product.fastDelivery && product.deliveryCalendar?.allowed){
        return "CALENDARIO"
    }
    if(product.fastDelivery){
        return "72 Horas"
    }
    if(product.shippingBySea){
        return "60-90 DIAS"
    }
    return "25-40 DIAS"
}

const isValidCalendarDate = (day, month, year, minHoursTime, maxHoursTime, maxTime) => {
    console.log(`${day}/${month}/${year}`)
    const cDateNow = Date.now()
    const cDate = new Date()
    if(day === cDate.getDate() && cDate.getMonth() === month-1 && cDate.getFullYear() === year){
        const addedMl = cDateNow + (minHoursTime*60*60*1000)
        const addedMlDate = new Date(addedMl)
        if(day === addedMlDate.getDate() && addedMlDate.getMonth() === month-1 && addedMlDate.getFullYear() === year){
            if(addedMlDate.getHours() <= militaryHoursClockConvertor(maxTime.hours, maxTime.ampm)){
                return {
                    isValid: true,
                    stopCheck: false,
                    reason: "first Date Valid"
                } 
            }
        }
        return {
            isValid: false,
            stopCheck: false,
            reason: "first Date Invalid For Hour"
        }
    }
    else{
        cDate.setHours(0)
        cDate.setMinutes(0)
        const currentDateMl = cDate.getTime()
        const dateToTest = new Date(year, month-1, day, 0, 0, 0, 0)
        const minAddedMl = currentDateMl + (minHoursTime*60*60*1000)
        if(dateToTest.getTime() < minAddedMl){
            return {
                isValid: false,
                stopCheck: false
            }
        }
        const maxAddedMl = currentDateMl + (maxHoursTime*60*60*1000)
        if(dateToTest.getTime() > maxAddedMl){
            return {
                isValid: false,
                stopCheck: true
            }
        }
        return {
            isValid: true,
            stopCheck: false
        }
    }
}

const isValidCalendarTime = (hours, minutes, ampm, minTime, maxTime, sameDay, minHoursTime) => {
    const militarySelectedHour = militaryHoursClockConvertor(hours,ampm)
    if(sameDay) {
        const date = new Date()
        if(date.getHours() + minHoursTime > militarySelectedHour){
            return {
                isValid: false,
                reason: "Min Hours Time Needed"
            }
        } 
    }
    console.log(militarySelectedHour)
    console.log(militaryHoursClockConvertor(minTime.hours, minTime.ampm))
    if(militarySelectedHour > militaryHoursClockConvertor(minTime.hours, minTime.ampm) 
    && militarySelectedHour < militaryHoursClockConvertor(maxTime.hours, maxTime.ampm)){
        return {
            isValid: true
        }
    }
    if(militarySelectedHour === militaryHoursClockConvertor(minTime.hours, minTime.ampm)){
        if(minutes >= minTime.minutes){
            return {
                isValid: true
            }
        }
    }
    if(militarySelectedHour === militaryHoursClockConvertor(maxTime.hours, maxTime.ampm)){
        if(minutes <= maxTime.minutes){
            return {
                isValid: true
            }
        }
    }
    return {
        isValid: false,
        reason: "Min/Max hour violation"
    }
}

const militaryHoursClockConvertor = (hours, ampm) => {
    if(hours === 12 && (ampm === "AM" || ampm === "am")) return 0
    if(ampm === "AM" || ampm === "am") return hours
    if(hours === 12 && (ampm === "PM" || ampm === "pm")) return 12
    if(ampm === "PM" || ampm === "pm") return hours + 12
    console.log(`ERROR. HOURS: ${hours}, AMPM: ${ampm}`)
}

const getYearsMonthsDaysAvailable = (product) => {
    let yearsMonthsDays = []
    const cDate = new Date()
    cDate.setHours(0)
    cDate.setMinutes(0)
    const cDateNow = cDate.getTime()
    const maxTimeMl = product.deliveryCalendar.maxHoursTime*60*60*1000
    for (let index = cDateNow; index < (cDateNow+maxTimeMl); index+=(24*60*60*1000)) {
        console.log(index)
        console.log(cDateNow+maxTimeMl)
        const dateIndex = new Date(index)
        const dateIndexResult = isValidCalendarDate(dateIndex.getDate(), dateIndex.getMonth()+1, dateIndex.getFullYear(), product.deliveryCalendar.minHoursTime, product.deliveryCalendar.maxHoursTime, product.deliveryCalendar.maxTime)
        console.log(dateIndexResult)
        if(dateIndexResult.isValid){
            const yearIndex = getIndexOfYear(yearsMonthsDays, dateIndex.getFullYear())
            if(yearIndex !== -1){
                const monthIndex = getIndexOfMonth(yearsMonthsDays[yearIndex].months, dateIndex.getMonth()+1)
                if(monthIndex !== -1){
                    yearsMonthsDays[yearIndex].months[monthIndex].days.push(dateIndex.getDate())
                }
                else{
                    yearsMonthsDays[yearIndex].months.push({month: dateIndex.getMonth()+1, days: [dateIndex.getDate()]})
                }
            }
            else{
                yearsMonthsDays.push({year: dateIndex.getFullYear(), months: [{month: dateIndex.getMonth()+1, days: [dateIndex.getDate()]}]})
            }
        }
        else{
            if(dateIndexResult.stopCheck) break;
        }
    }
    return yearsMonthsDays
}

const getIndexOfYear = (years, year) => {
    let findIndex = -1
    for (let index = 0; index < years.length; index++) {
        const indexYear = years[index].year;
        if(indexYear === year){
            findIndex = index
            break
        }
    }
    return findIndex
}

const getIndexOfMonth = (months, month) => {
    let findIndex = -1
    for (let index = 0; index < months.length; index++) {
        const indexMonth = months[index].month;
        if(indexMonth === month){
            findIndex = index
            break
        }
    }
    return findIndex
}

const getMonthString = (monthIndex) => {
    switch (monthIndex) {
        case 1:
            return "ENE"
        case 2:
            return "FEB"
        case 3:
            return "MAR"
        case 4:
            return "ABR"
        case 5:
            return "MAY"
        case 6:
            return "JUN"
        case 7:
            return "JUL"
        case 8:
            return "AGO"
        case 9:
            return "SEP"
        case 10:
            return "OCT"
        case 11:
            return "NOV"
        default:
            return "DIC"
    }
}

const getDeliveryCalendarString = (deliveryCalendar) => {
    if(deliveryCalendar?.allowed){
        let deliveryCalendarString = ""
        deliveryCalendarString += `${deliveryCalendar.day}/`
        deliveryCalendarString += `${getMonthString(deliveryCalendar.month)}/`
        deliveryCalendarString += `${getTimeString(deliveryCalendar.year)} `
        deliveryCalendarString += `${getTimeString(deliveryCalendar.hours)}:`
        deliveryCalendarString += `${getTimeString(deliveryCalendar.minutes)} `
        deliveryCalendarString += `${getTimeString(deliveryCalendar.ampm)}`
        return deliveryCalendarString
    }
    return ""
}



const getOriginalPrice = (product) => {
    switch (product.platformEarningType) {
        case 'fixed':{
            return reduceDecimalValue(product.price - product.fixedPlatformEarning)
        }
        default:{
            let originalPrice = (product.price*100)/(product.percent + 100)
            return reduceDecimalValue(originalPrice)
        }
    }
}

const getOriginalPriceInSelectedCurrency = (product) => {
    switch (product.platformEarningType) {
        case 'fixed':{
            return reduceDecimalValue((product.price - product.fixedPlatformEarning)*product.currencyExchangeValue)
        }
        default:{
            let originalPrice = (product.price*100)/(product.percent + 100)*product.currencyExchangeValue
            return reduceDecimalValue(originalPrice)
        }
    }
}

const getProductEarningTypeAndValueString = (product) => {
    switch (product.platformEarningType) {
        case 'fixed':{
            return `${product.fixedPlatformEarning} (FIJO)`
        }
        default:{
            return `${product.percent} %`
        }
    }
}

const showInfo = (field) => {
    if(field){
        return true
    }
    return false
}


export default reduceDecimalValue
export { 
    calcSendingCost, 
    getDeliveryTime, 
    isValidCalendarDate, 
    isValidCalendarTime, 
    militaryHoursClockConvertor, 
    getYearsMonthsDaysAvailable,
    getIndexOfYear, 
    getIndexOfMonth,
    getMonthString,
    getDeliveryCalendarString,
    getOriginalPrice,
    getOriginalPriceInSelectedCurrency,
    getProductEarningTypeAndValueString,
    showInfo,
    getTotalPrice
}