import React, { useRef } from 'react'
import { createTheme } from '@material-ui/core/styles';
import { alpha, Button, Card, Checkbox, CircularProgress, FormControl, IconButton, InputBase, makeStyles, Menu, MenuItem, Modal, MuiThemeProvider, Select, Snackbar, Tooltip, Typography } from '@material-ui/core'
import url, { colors, sendingCostValue, guideCut, packaging, provinces, municipalities, discountOverTotalPrice, discountOverValue, discountOverTotalPriceMessage, allowedAduanaPoints, paymentTypeSelectors, paymentStripeCurrencies } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { AccountBalanceWallet, ArrowDropDown, AttachMoney, Cancel, CreditCard, DeleteForever, Flight, LocalOffer, LocalShipping, LocationOn, Person, Phone, RecentActors, RemoveShoppingCart, Score } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { restart_cart_action, update_cart_action } from '../../redux/actions/cartAction'
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import b2_truck from '../../assets/promotional_banner/b2_truck.png'
import Navbar from '../global/NavbarGlobal';
import TitleWithBottomArrow from '../global/TitleWithBottomArrow';
import reduceDecimalValue, { calcSendingCost, getDeliveryCalendarString, getDeliveryTime, getYearsMonthsDaysAvailable, isValidCalendarDate, isValidCalendarTime } from '../../tools/productTools';
import Footer from '../global/Footer';
import airTaxImage from '../../assets/img/air_tax_icon.png'
import sendCostIcon from '../../assets/img/send_cost_icon.png'
import walletImage from '../../assets/img/wallet_icon.jpg'
import { FavoritesPageProductList } from '../pagesComponents/FavoritesPage/FavoritesPageProductList';
import AddDeliveryAddressModal from '../global/AddDeliveryAddressModal';
import userPlaceholder from '../../assets/img/userPlaceholder.png'
import hasSizeAndColor from '../../tools/tagsTools';
import { check200FamilyStatus } from '../../tools/reqResTools';
import PickCalendarProduct from '../global/PickCalendarProduct';
import { getTimeString } from '../../tools/userTools';
import { getPaymentTypeName, getProductDiscountForCoupon } from '../../tools/orderTools';

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    cartHolder: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
    },
    blueBackground: {
        backgroundColor: colors.cartBlueBackground,
        paddingTop: '30px',
        paddingBottom: '30px', 
    },
    cardContainer: {
        margin: '10px 20px',
    },
    informativeCartNote: {
        marginBottom: '15px',
        fontSize: '13px',
        textAlign: 'center',
        opacity: '0.8'
    },
    cartContainer: {
        flex: 1,
        backgroundColor: colors.whiteAppbar,
        marginLeft: '10px',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '30px',
            marginLeft: '0px'
        },
    },
    cartCheckOut: {
        marginLeft: '20px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    checkOutHolder: {
        backgroundColor: colors.whiteAppbar,
        width: 'inherit',
        paddingTop: '25px',
        width: '312px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            paddingBottom: '15px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
            display: 'flex'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inherit',
            width: '100%',
        },
        
    },
    checkOutPrimaryText: {
        color: colors.primary,
        fontSize: '20px',
        fontWeight: '600',
        marginRight: 'auto'
    },
    checkOutSecondaryText: {
        color: colors.secondary,
        fontSize: '18px',
        fontWeight: '600',
        marginRight: 'auto'
    },
    checkOutLeftSecondaryText: {
        color: colors.secondary,
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'right',
        paddingRight: '5px'
    },
    checkOutSecondarySmallText: {
        color: colors.secondary,
        fontSize: '15px',
        fontWeight: '600',
    },
    checkOutLeftSecondarySmallText: {
        color: colors.secondary,
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'right',
        paddingRight: '5px'
    },
    couponDiscountMainText: {
        fontSize: '13px',
        fontWeight: '600',
        padding: '0px 10px'
    },
    couponDiscountSecondaryText: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '0px 10px'
    },
    couponDiscountThirdText: {
        fontSize: '11px',
        fontWeight: '600',
        padding: '0px 10px'
    },
    remainingPointsText: {
        fontSize: '11px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
    },
    totalText: {
        fontSize: '26px',
        fontWeight: '600',
        color: colors.secondary,
        paddingBottom: '5px',
        paddingTop: '5px',
        textAlign: 'center'
    },
    infoHeaderText: {
        fontSize: '20px',
        fontWeight: '600',
        color: colors.secondary,
        textAlign: 'center'
    },
    infoText: {
        padding: '2px 15px',
        fontSize: '13px',
        fontWeight: '600'
    },
    lightGrayDivider: {
        width: '90%',
        margin: '0px auto',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.1',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    lightGrayDividerElement: {
        width: 'calc(100%-40px)',
        margin: '7px 20px',
        height: '1.5px',
        backgroundColor: colors.secondary,
        opacity: '0.2',
    },
    miscellanyContainer: {
        flex: 1,
    },
    miscellanyItems: {
        position: 'relative',
        //borderRight: `solid 1.5px ${colors.primary}`,
        //minHeight: '280px',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]:{
            borderRight: `solid 0px ${colors.primary}`,
        }
    },
    otherProductsContainer: {
        flex: 1
    }, 
    subTotalContainer: {
        [theme.breakpoints.down('xs')]:{
            display: 'none',
        }
    },
    otherProductsItems: {
        [theme.breakpoints.down('xs')]:{
            marginTop: '30px',
        }
    },
    cartTypeText: {
        fontSize: '18px',
        marginLeft: '0px',
        fontWeight: '700',
        marginBottom: '15px',
        //textAlign: 'center',
        color: colors.secondary
    }, 
    emptyCartContainer: {
        paddingTop: '80px',
        paddingBottom: '130px', 
        marginBottom: '30px'
    },
    emptyCartIcon: {
        height: '80px',
        width: '80px',
        margin: '0px auto 10px auto',
        display: 'block',
        color: colors.secondary,
    }, 
    emptyCartPrimaryText:{
        textAlign: 'center',
        fontWeight: '600',
    },
    emptyCartSecondaryText: {
        textAlign: 'center',
        fontSize: '14px'
    },
    elementHolder: {
        display: 'flex',
        margin: '10px 20px 20px 20px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 14px 10px 14px',
        }
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            flex: 1, 
        }
    },
    elementImage: {
        height: '140px',
        width: '140px',
        borderRadius: '3px',
        marginRight: '6px'
    },
    elementInfoAndPrice: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1
        }
    },
    elementInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
        }
    },
    priceInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
            marginLeft: '10px'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    currencyPrice: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '8px',
            fontWeight: '700'
        }
    },
    priceInfoResponsive: {
        display: 'none',
        flex: 1,
        flexDirection: 'column',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start'
        },
    },
    leftInfoIcon: {
        height: '20px',
        opacity: '0.87',
    },
    leftInfoIconFilled: {
        height: '14px',
        width: '15px',
        padding: '0px 1px',
        margin: '1px 4.5px',
        opacity: '0.77',
        backgroundColor: 'black',
        color: colors.whiteAppbar,
        borderRadius: '2px'
    },
    leftInfoTag: {
        fontSize: '10px',
        fontWeight: '600',
    },
    rightInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            marginRight: '25px'
        }
    },
    elementText: {
        fontSize: '14px',
        fontWeight: '600',
        padding: '0px 15px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px',
        } 
    }, 
    elementPrice: {
        fontSize: '14px',
        fontWeight: '600',
        color: colors.secondary 
    }, 
    elementTotal: {
        display: 'flex',
        paddingBottom: '10px',
        justifyContent: 'flex-end',
        paddingTop: '5px',
        width: 'calc(100%)',
        borderTop: `solid 1.5px ${colors.primary}`
    },
    elementHeader: {
        fontSize: '16px',
        fontWeight: '600',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementFreeSendingCost: {
        fontSize: '11px',
        fontWeight: '700',
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementFirstHeader: {
        fontSize: '25px',
        fontWeight: '700',
        // textAlign: 'center',
        color: colors.primary,
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    elementSecondary: {
        fontSize: '16px',
        lineHeight: '20px',
        paddingRight: '20px',
        paddingLeft: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    airTaxImage: {
        height: '60px',
        width: '60px',
        marginLeft: '20px',
    },
    userImage: {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        marginLeft: '-10px',
        marginRight: '-12px',
    },
    walletImage: {
        height: '60px',
        width: '60px',
        marginLeft: '27px',
    },
    elementUnit: {
        textAlign: 'end',
        paddingLeft: '5px',
        fontSize: '16px',
        fontWeight: '600',
        color: colors.secondary,
    },
    elementSecondaryInfoHeader: {
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    elementSecondaryInfoUnit: {
        marginLeft: '3px',
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    minusCart: { 
        border: `solid 2.3px ${colors.secondary}`,
        marginLeft: '5px',
        width: '30px',
        borderRight: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '17px',
        fontWeight: '800',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        },
    },
    sumCart: {
        border: `solid 2.3px ${colors.secondary}`,
        width: '30px',
        borderLeft: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '20px',
        fontWeight: '800',
        userSelect: 'none',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        }
    },
    cartCount: {
        borderTop: `solid 2.3px ${colors.cartProductCountBackground}`,
        borderBottom: `solid 2.3px ${colors.cartProductCountBackground}`,
        backgroundColor: colors.cartProductCountBackground,
        fontSize: '14px',
        paddingLeft: '12px',
        paddingRight: '12px',
        textAlign: 'center',
        lineHeight: '22px',
        fontWeight: '700',
    },
    deleteButton: {
        marginLeft: '15px', 
        fontSize: '29px',
        '&:hover': {
            color: colors.redError,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0px',
            marginLeft: '4px',
            fontSize: '16px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    makeOrderButton: {
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        borderRadius: '0px',
        fontSize: '18px',
        width: '100%',
        backgroundColor: colors.secondary,
        boxShadow: '0px 0px 0px',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.9),
            boxShadow: '0px 0px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'inherit',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderRadius: '7px'
        }
    },
    addButton: {
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        borderRadius: '0px',
        fontSize: '14px',
        height: '40px',
        borderRadius: '7px',
        width: '180px',
        backgroundColor: colors.secondary,
        boxShadow: '0px 0px 0px',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.9),
            boxShadow: '0px 0px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'inherit',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    confirmButton: {
        height: '35px',
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        marginBottom: '2px',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.2)
        }
    },
    countAndPriceHolder: {
        marginLeft: '10px', 
        display: 'flex',  
        alignItems: 'center',
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'5px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputHolderFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    inputRoot: {
        width: '100%',
    },
    division: {
        height: '1px', 
        marginLeft: '10px', 
        marginRight: '10px', 
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        animation: '$enter 350ms ease-in',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px'
          },
    },
    divisionSummarize: {
        height: '1px', 
        marginLeft: '20px', 
        marginRight: '20px', 
        marginTop: '5px',
        marginBottom: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        animation: '$enter 350ms ease-in',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px'
          },
    },
    inputInput: {
        height: 24,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
        },
        
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
        },
        
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
        },
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        width: '400px',
        maxHeight: '90%',
        maxWidth: '95%',
        overflowY: 'scroll',
        transform: `translate(-50%, -50%)`,
        borderRadius: '3px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
        display: 'block',
        marginLeft: 'auto',
        padding: '6px'
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    dataSelect: {
        paddingLeft: '10px',
    },
    formControl: {
        flex: 1
    },
    dividerLongSize: {
        width: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    colorIcon: {
        color: colors.primary,
        padding:'0px',
        paddingLeft: '6px'
    },
    favoritesList: {
        marginTop: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    externalHolder: {
        display: 'flex',
        marginLeft: '20px',
        marginRight: '20px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    addressInternalHolder: {
        display: 'flex', 
        alignItems: 'center',
        paddingRight: '15px',
        flex: 1,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    buttonInternalHolder: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    svg: {
        width: "20px",
        height: "10px",
    },
    polygon: {
        fill: colors.secondary,
      },
      deliveryTimeText: {
        fontSize: '12px',
        fontWeight: '600',
        paddingLeft: '3px',
        textAlign: 'left',
        width: '100%',
        marginTop: '5px'
    },
    inputVerificateHolder: {
        display: 'flex',
        width: '35px',
        height: '35px',
        marginLeft: '2px',
        marginRight: '2px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          },
          '&:focus': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          }
    },
    inputRootVerificate: {
        width: '100%',
    },
    inputInputVerificate: {
        height: 22,
        fontSize: '16px',
        fontWeight: '600',
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '13px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    couponResultText: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '600',
        marginTop: '7px'
    },
    ButtonHolder: {
        margin: '5px 10px 15px 10px',
        position: 'relative'
    },
    paymentTypeButton: {
        width: '100%',
        backgroundColor:'#ffffff', 
        borderRadius: '3px',
        justifyContent:'center',
        paddingLeft:'10px',
        '&:hover': {
          backgroundColor: alpha('#ffffff', 1),
        }
      },
      labelCategory: {
        textTransform: 'capitalize',
        fontWeight: "600",
      },
}))

const CartPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDeliveryCost, setTotalDeliveryCost] = useState(0)
    const [totalCouponDiscount, setTotalCouponDiscount] = useState(0)
    const [importProducts, setImportProducts] = useState([])
    const [orderId, setOrderId] = useState("0")

    const [openModal, setOpenModal] = useState(false)
    const [snackProps, setSnackProps] = useState({open: false, autoHideDuration: 2000, severity: "success", text: ""})

    const [address, setAddress] = useState()
    const [added, setAdded] = useState(0)
    const [cartText, setCartText] = useState('')
    const [openCartSnack, setOpenCartSnack] = useState(false)
    const [openOtherInProcessSnack, setOpenOtherInProcessSnack] = useState(false)
    const [openNotAvailableSnack, setOpenNotAvailableSnack] = useState(false)
    const [openOtherSuccessSnack, setOpenOtherSuccessSnack] = useState(false)
    const [openRedirectingSnack, setOpenRedirectingSnack] = useState(false)
    const [openRedirectingStripeSnack, setOpenRedirectingStripeSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openCartErrorSnack, setOpenCartErrorSnack] = useState(false)
    const [updatingCartIndexMinus, setUpdatingCartIndexMinus] = useState(-1)
    const [updatingCartIndexAdd, setUpdatingCartIndexAdd] = useState(-1)
    const [deletingCartIndex, setDeletingCartIndex] = useState(-1)

    const [editingDeliveryCalendar, setEditingDeliveryCalendar] = useState(false)
    const [updateDeliveryCalendarIndex, setUpdateDeliveryCalendarIndex] = useState(-1)

    const [selectedDate, setSelectedDate] = useState({
        day: -1,
        month: -1,
        year: -1,
        hours: -1,
        minutes: -1,
        ampm: "AM"
    })

    const [yearsMonthsDaysAvailable, setYearsMonthsDaysAvailable] = useState([])
    const [deliveryCalendarErrorText, setDeliveryCalendarErrorText] = useState("")

    const [discountCoupon1, setDiscountCoupon1] = useState("")
    const [discountCoupon2, setDiscountCoupon2] = useState("")
    const [discountCoupon3, setDiscountCoupon3] = useState("")
    const [discountCoupon4, setDiscountCoupon4] = useState("")
    const [discountCoupon5, setDiscountCoupon5] = useState("")
    const [discountCoupon6, setDiscountCoupon6] = useState("")

    const [couponResultText, setCouponResulText] = useState("")
    const [couponInfo, setCouponInfo] = useState()

    const [wallet, setWallet] = useState(0)
    const [useWallet, setUseWallet] = useState(false)

    const [open, setOpen] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false)

    const [openEditDeliveryCalendar, setOpenEditDeliveryCalendar] = useState(false)

    const [isDiscountOverTotalPrice, setIsDiscountOverTotalPrice] = useState(false)
    

    const [confirmingOrder, setConfirmingOrder] = useState(false)

    const [airWaybill, setAirWaybill] = useState(0)


    const cart_store_selector = useSelector((state)=> state.cart_store)
    const user = useSelector((state) => state.user_store.user)
    const accessToken = useSelector((state) => state.user_store.accessToken)

    const [notAvailableinProvince, setNotAvailableInProvince] = useState(false)

    const [anchorElPaymentType, setAnchorElPaymentType] = useState(null);
    const openPaymentType = Boolean(anchorElPaymentType);
    const [selectedPaymentType, setSelectedPaymentType] = useState("SELECT ONE")
    const [paymentTypeErrorOpen, setPaymentTypeErrorOpen] = useState(false)
    const [paymentTypeError, setPaymentTypeError] = useState("")

    const [anchorElPaymentStripeCurrencies, setAnchorElPaymentStripeCurrencies] = useState(null);
    const openPaymentStripeCurrencies = Boolean(anchorElPaymentStripeCurrencies);
    const [selectedPaymentStripeCurrencies, setSelectedPaymentStripeCurrencies] = useState("SELECCIONE UNA")
    const [paymentStripeCurrenciesErrorOpen, setPaymentStripeCurrenciesErrorOpen] = useState(false)
    const [paymentStripeCurrenciesError, setPaymentStripeCurrenciesError] = useState("")

    useEffect(() => {
        try{
            if(updateDeliveryCalendarIndex !== -1 && importProducts[updateDeliveryCalendarIndex]?.deliveryCalendar?.allowed){
                let yearsMonthsDays = getYearsMonthsDaysAvailable(importProducts[updateDeliveryCalendarIndex].product)
                if(yearsMonthsDays.length > 0){
                    if(yearsMonthsDays[0].months.length > 0){
                        setSelectedDate({
                            ...selectedDate,
                            year: yearsMonthsDays[0].year,
                            month: yearsMonthsDays[0].months[0].month
                        })
                    }
                    else{
                        setSelectedDate({
                            ...selectedDate,
                            year: yearsMonthsDays[0].year
                        })
                    }
                }
                setYearsMonthsDaysAvailable(yearsMonthsDays)
            }
        }
        catch(e){
            console.log(e)
        }
    }, [updateDeliveryCalendarIndex])

    useEffect(() => {
        if(user && !address){
            if(user.deliveryAddresses?.length > 0){
                for (let index = 0; index < user.deliveryAddresses.length; index++) {
                    const element = user.deliveryAddresses[index];
                    if(element.isDefault){
                        setAddress({
                            name: element.name,
                            address: element.address,
                            region: element.region,
                            municipality: element.municipality,
                            phone: element.phone,
                            ci: element.ci,
                            isDefault: element.isDefault
                        }) 
                        break;     
                    }
                }
            
            }
        }
    }, [user])

    useEffect(() => {
        if(added){
            const lastIndex = user.deliveryAddresses.length - 1
            setAddress({
                name: user.deliveryAddresses[lastIndex].name,
                address: user.deliveryAddresses[lastIndex].address,
                region: user.deliveryAddresses[lastIndex].region,
                municipality: user.deliveryAddresses[lastIndex].municipality,
                phone: user.deliveryAddresses[lastIndex].phone,
                ci: user.deliveryAddresses[lastIndex].ci,
                isDefault: user.deliveryAddresses[lastIndex].isDefault
            })
        }
    }, [added])

    useEffect(() => {
        const updateWallet = async () => {
            try{
                if(user){
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}` }
                    };
                    let result = await fetch(`${url}/api/accountImport/${user._id}`, requestOptions)
                    if(result.status === 200){
                        let dataResult = await result.json()
                        setWallet(dataResult.wallet? dataResult.wallet : 0)
                    }
                }
                
            } catch(error){
                setWallet(0);
            }
        }
        updateWallet()
    },[user])

    useEffect(() => {
        const updateProducts = () => {
            let fastDelivery = 0
            if(cart_store_selector.products?.length > 0){
                let othProducts = cart_store_selector.products
                othProducts.sort((a, b) => {
                    if(a.product.fastDelivery && !b.fastDelivery){
                        return -1
                    }
                    if(b.product.fastDelivery && !a.fastDelivery){
                        return 1
                    }
                    if(a.product.user < b.product.user){
                        return -1
                    }
                    if(a.product.user > b.product.user){
                        return 1
                    }
                    if(a.product.user === b.product.user){
                        return 0
                    }
                })
                
                let totalP = 0
                let totalDeliveryC = 0
                let totalCouponDiscountC = 0
                for (let index = 0; index < othProducts.length; index++) {
                    const element = othProducts[index];
                    totalP += ((element.product.price+calcSendingCost(element.product))*element.count)
                    if(couponInfo && element.product.applyDiscount){
                        totalCouponDiscountC += (getProductDiscountForCoupon(element.product, couponInfo.productDiscount)*element.count)
                    }
                    if(!element.product.fastDelivery){
                        fastDelivery = guideCut + packaging
                    }
                    if(address){
                        if(index < othProducts.length-1){
                            const element = othProducts[index];
                            const nextElement = othProducts[index+1]
                            if(element.product.user._id !== nextElement.product.user._id){
                                totalDeliveryC += getDeliveryCost(element.product.user, address, element.product.fastDelivery, othProducts)
                            }
                            else{
                                if(element.product.fastDelivery && !nextElement.product.fastDelivery){
                                    totalDeliveryC += getDeliveryCost(element.product.user, address, element.product.fastDelivery, othProducts)
                                }
                            }
                        }
                        else{
                            //CASO ULTIMO PRODUCTO
                            const element = othProducts[index];
                            totalDeliveryC += getDeliveryCost(element.product.user, address, element.product.fastDelivery, othProducts)
                            //!!!ELIMINAR CODIGO COMENTADO SI NO HAY ERROR
                            // if(index === 0){
                            //     totalDeliveryC += getDeliveryCost(element.product.user, address, element.product.fastDelivery, othProducts)
                            //     console.log("ENTRO EN 3 INDEX: ", index)
                            // }
                            // else{
                            //     const prevElement = othProducts[index-1]
                            //     if(element.product.user._id !== prevElement.product.user._id){
                            //         totalDeliveryC += getDeliveryCost(element.product.user, address, element.product.fastDelivery, othProducts)
                            //         console.log("ENTRO EN 4 INDEX: ", index)
                            //     }
                            // }
                        }
                    }
                }
                setTotalPrice(totalP)
                setTotalDeliveryCost(totalDeliveryC)
                setTotalCouponDiscount(totalCouponDiscountC)
                if(totalP + guideCut + packaging >= discountOverValue){
                    if(fastDelivery > 0){
                        setIsDiscountOverTotalPrice(true)
                    }
                    if(fastDelivery >= discountOverTotalPrice){
                        setAirWaybill(fastDelivery - discountOverTotalPrice)
                    }
                    else{
                        setAirWaybill(0)
                    }
                }
                else{
                    setIsDiscountOverTotalPrice(false)
                    if(cart_store_selector.points > 0 && cart_store_selector.points < (allowedAduanaPoints/2/2)){
                        setAirWaybill((fastDelivery/2)/2)
                    }
                    if(cart_store_selector.points >= (allowedAduanaPoints/2/2) && cart_store_selector.points <= (allowedAduanaPoints/2)){
                        setAirWaybill(fastDelivery/2)
                    }
                    if(cart_store_selector.points > (allowedAduanaPoints/2)){
                        setAirWaybill(fastDelivery)
                    }
                }
                setImportProducts(othProducts)
                
            }
            else{
                setTotalPrice(0)
                setAirWaybill(0)
                setTotalDeliveryCost(0)
                setTotalCouponDiscount(0)
                setImportProducts([])
            }
        }
        updateProducts()
    },[cart_store_selector.products, address, couponInfo])

    useEffect(() => {
        const verifyCoupon = async () => {
            if(discountCoupon1.length === 0 || 
                discountCoupon2.length === 0 ||
                discountCoupon3.length === 0 ||
                discountCoupon4.length === 0 ||
                discountCoupon5.length === 0 ||
                discountCoupon6.length === 0){
                    setCouponResulText("")
                    setCouponInfo()
                    return
            }
            setCouponResulText("VERIFICANDO CUPON, ESPERE POR FAVOR")
            let requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `${accessToken}`
                },
            };
            let urlGetProduct = `${url}/api/accountImport/getCouponInfo?couponInfo=${discountCoupon1}${discountCoupon2}${discountCoupon3}${discountCoupon4}${discountCoupon5}${discountCoupon6}`
            let result = await fetch(urlGetProduct, requestOptions)
            console.log(result)
            if(result.status === 200){
                const data = await result.json()
                setCouponInfo(data.couponInfo)
                setCouponResulText("CUPON APLICADO")
            }
            else{
                if(result.status === 400){
                    const data = await result.json()
                    if(data.errorType === 3){
                        setCouponInfo()
                        setCouponResulText("NO PUEDE USAR SU PROPIO CUPON")
                        return
                    }
                }
                setCouponInfo()
                setCouponResulText("CUPON INCORRECTO")
            }
        }
        verifyCoupon()

    }, [discountCoupon1, discountCoupon2, discountCoupon3, discountCoupon4, discountCoupon5, discountCoupon6])

    useEffect(() => {
        if(orderId !== "0"){
            setOpenOtherSuccessSnack(true)
        }

    }, [orderId])

    const dispatch = useDispatch()

    const handlePaymentTypeClick = (event) => {
        if(paymentTypeError) setPaymentTypeError("")
        setAnchorElPaymentType(event.currentTarget);
    };

    const handlePaymentTypeClose = (selected) => {
        setAnchorElPaymentType(null);
        if (selected !== "-1"){
            setSelectedPaymentType(selected)   
        }
    };

    const handlePaymentTypeErrorTooltipClose = () => {
        setPaymentTypeErrorOpen(false);
    };

    const handlePaymentTypeErrorTooltipOpen = () => {
    setPaymentTypeErrorOpen(true);
    };

    const handlePaymentStripeCurrenciesClick = (event) => {
        if(paymentStripeCurrenciesError) setPaymentStripeCurrenciesError("")
        setAnchorElPaymentStripeCurrencies(event.currentTarget);
    };

    const handlePaymentStripeCurrenciesClose = (selected) => {
        setAnchorElPaymentStripeCurrencies(null);
        if (selected !== "-1"){
            setSelectedPaymentStripeCurrencies(selected)   
        }
    };

    const handlePaymentStripeCurrenciesErrorTooltipClose = () => {
        setPaymentStripeCurrenciesErrorOpen(false);
    };
    
    const handlePaymentStripeCurrenciesErrorTooltipOpen = () => {
    setPaymentStripeCurrenciesErrorOpen(true);
    };

    const handleUseWalletChange = (event) => {
        setUseWallet(event.target.checked)
    }

    const handleChangeDiscountCoupon1 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon1(event.target.value);
        if(!discountCoupon2)
        {
            selectDiscountCouponNextSibling(2)
            return
        }
        if(!discountCoupon3)
        {
            selectDiscountCouponNextSibling(3)
            return
        }
        if(!discountCoupon4)
        {
            selectDiscountCouponNextSibling(4)
            return
        }
        if(!discountCoupon5)
        {
            selectDiscountCouponNextSibling(5)
            return
        }
        if(!discountCoupon6)
        {
            selectDiscountCouponNextSibling(6)
            return
        }
    };

    const handleChangeDiscountCoupon2 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon2(event.target.value);
        if(!discountCoupon3)
        {
            selectDiscountCouponNextSibling(3)
            return
        }
        if(!discountCoupon4)
        {
            selectDiscountCouponNextSibling(4)
            return
        }
        if(!discountCoupon5)
        {
            selectDiscountCouponNextSibling(5)
            return
        }
        if(!discountCoupon6)
        {
            selectDiscountCouponNextSibling(6)
            return
        }
        if(!discountCoupon1)
        {
            selectDiscountCouponNextSibling(1)
            return
        }
    };

    const handleChangeDiscountCoupon3 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon3(event.target.value);
        if(!discountCoupon4)
        {
            selectDiscountCouponNextSibling(4)
            return
        }
        if(!discountCoupon5)
        {
            selectDiscountCouponNextSibling(5)
            return
        }
        if(!discountCoupon6)
        {
            selectDiscountCouponNextSibling(6)
            return
        }
        if(!discountCoupon1)
        {
            selectDiscountCouponNextSibling(1)
            return
        }
        if(!discountCoupon2)
        {
            selectDiscountCouponNextSibling(2)
            return
        }
    };

    const handleChangeDiscountCoupon4 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon4(event.target.value);
        if(!discountCoupon5)
        {
            selectDiscountCouponNextSibling(5)
            return
        }
        if(!discountCoupon6)
        {
            selectDiscountCouponNextSibling(6)
            return
        }
        if(!discountCoupon1)
        {
            selectDiscountCouponNextSibling(1)
            return
        }
        if(!discountCoupon2)
        {
            selectDiscountCouponNextSibling(2)
            return
        }
        if(!discountCoupon3)
        {
            selectDiscountCouponNextSibling(3)
            return
        }
    };

    const handleChangeDiscountCoupon5 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon5(event.target.value);
        if(!discountCoupon6)
        {
            selectDiscountCouponNextSibling(6)
            return
        }
        if(!discountCoupon1)
        {
            selectDiscountCouponNextSibling(1)
            return
        }
        if(!discountCoupon2)
        {
            selectDiscountCouponNextSibling(2)
            return
        }
        if(!discountCoupon3)
        {
            selectDiscountCouponNextSibling(3)
            return
        }
        if(!discountCoupon4)
        {
            selectDiscountCouponNextSibling(4)
            return
        }
    };

    const handleChangeDiscountCoupon6 = (event) => {
        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
        setDiscountCoupon6(event.target.value);
        if(!discountCoupon1)
        {
            selectDiscountCouponNextSibling(1)
            return
        }
        if(!discountCoupon2)
        {
            selectDiscountCouponNextSibling(2)
            return
        }
        if(!discountCoupon3)
        {
            selectDiscountCouponNextSibling(3)
            return
        }
        if(!discountCoupon4)
        {
            selectDiscountCouponNextSibling(4)
            return
        }
        if(!discountCoupon5)
        {
            selectDiscountCouponNextSibling(5)
            return
        }
    };

    const selectDiscountCouponNextSibling = (index) => {
        const nextSibling = document.querySelector(
            `input[name=disc-coup-${index}]`
        );
      // If found, focus the next field
        if (nextSibling !== null) {
            nextSibling.focus();
        }
    }

    const getDeliveryCost = (productUser, address, fastDelivery, products) => {
        if(productUser.deliveryCosts && productUser.deliveryCosts.length > 0){
            for(const province of productUser.deliveryCosts){
                if(province.provinceName === address.region){
                    if(province.available){
                        setNotAvailableInProvince(false)
                        if(province.isFree?.always) {
                            setNotAvailableInProvince(false)
                            return 0
                        }
                        if(province.isFree?.above) {
                            const isAbove = getCheckAbovePassed(province.isFree.aboveNumber, productUser, products, fastDelivery)
                            if(isAbove){
                                setNotAvailableInProvince(false)
                                return 0
                            }
                        }
                        for(const municipality of province.municipalities){
                            if(municipality.name === address.municipality){
                                setNotAvailableInProvince(false)
                                return municipality.cost
                            }
                        }
                    }
                    else{
                        setNotAvailableInProvince(true)
                        return 0
                    }
                }
            }
        }
        setNotAvailableInProvince(false)
        return 0
    }

    const getCheckAbovePassed = (aboveNumber, user, products, fastDelivery) => {
        const filterProducts = products.filter((p) => {
            return(p.product.user._id === user._id && p.product.fastDelivery === fastDelivery)
        })
        let total = 0
        for(const product of filterProducts){
            total += ((product.product.price+calcSendingCost(product.product))*product.count)
        }
        console.log(total)
        if(total >= aboveNumber) return true
        return false
    }

    const handleCloseCartSnack = () => {
        setOpenCartSnack(false)
    }
    const handleCloseCartErrorSnack = () => {
        setOpenCartErrorSnack(false)
    }
    const handleCloseOtherInProcessSnack = () => {
        setOpenOtherInProcessSnack(false)
    }
    const handleCloseNotAvailableSnack = () => {
        setOpenNotAvailableSnack(false)
    }
    const handleCloseOtherSuccessSnack = async () => {
        setOpenOtherSuccessSnack(false)
        if(selectedPaymentType === "OnlineStripe" && orderId !== "0"){
            if(getTotalCheckout(totalPrice, airWaybill, totalDeliveryCost, totalCouponDiscount, wallet, useWallet) <= 0){
                dispatch(restart_cart_action())
                setOpenRedirectingSnack(true)
                return
            }
            setOpenRedirectingStripeSnack(true)
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json',
                            'Authorization': `${accessToken}`
                },
            };
            let result = await fetch(`${url}/api/orders/payCheckout/?orderId=${orderId}`, requestOptions);
            if(result.status === 200){
                const data = await result.json()
                dispatch(restart_cart_action())
                window.location.href = data.stripeUrl;
            }
            else{
                dispatch(restart_cart_action())
                setOpenRedirectingSnack(true)
            }
        }
        else{
            dispatch(restart_cart_action())
            setOpenRedirectingSnack(true)
        }
    }

    const handleCloseRedirectingSnack = () => {
        setConfirmingOrder(false)
        setOpenRedirectingSnack(false)
        if(orderId !== "0"){
            history.push(`/orders/${user._id}/${orderId}`)
        }
        else{
            history.push(`/orders`)
        }

    }
    const handleCloseRedirectingStripeSnack = () => {
        setOpenRedirectingStripeSnack(false)

    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }

    const getAvailableText = (size, color, product) => {
        if(size && color){
            for(const t of product.availableCountByTags){
                if(t.size === size && t.color === color){
                    if(product.fastDelivery) {
                        return `DISPONIBLE: ${t.availableCount}`
                    }
                    else{
                        if(t.availableCount > product.maxCount) return `DISPONIBLE: ${product.maxCount}`
                        else return product.availableCount
                    }
                }
            }
        }
        if(product.fastDelivery) return `DISPONIBLE: ${product.availableCount}`
        else {
            if(product.availableCount > product.maxCount) return `DISPONIBLE: ${product.maxCount}`
            else return `DISPONIBLE: ${product.availableCount}`
        }
    }

    const getAvailableColorText = (size, color, product, count) => {
        if(size && color){
            for(const t of product.availableCountByTags){
                if(t.size === size && t.color === color){
                    if(product.fastDelivery){
                        if(count > t.availableCount) return true
                        return false
                    }
                    else{
                        if(count > product.maxCount || count > t.availableCount) return true
                        return false
                    }
                }
            }
        }
        if(product.fastDelivery){
            if(count > product.availableCount) return true
            return false
        }
        else {
            if(count > product.maxCount || count > product.availableCount) return true
            return false
        }
    }

    const handleSelectAddress = (index) => {
        setAddress({
            name: user.deliveryAddresses[index].name,
            address: user.deliveryAddresses[index].address,
            region: user.deliveryAddresses[index].region,
            municipality: user.deliveryAddresses[index].municipality,
            phone: user.deliveryAddresses[index].phone,
            ci: user.deliveryAddresses[index].ci,
            isDefault: user.deliveryAddresses[index].isDefault
        })
        setOpen(false)
    }

    const elementCard = (element, index, address, addSendingCost, showUser, firstImport, firstDelivery, products) => {
        const checkAbovePassed = (aboveNumber) => {
            const filterProducts = products.filter((p) => {return(p.product.user._id === element.product.user._id && p.product.fastDelivery === element.product.fastDelivery)})
            let total = 0
            for(const product of filterProducts){
                total += ((product.product.price+calcSendingCost(product.product))*product.count)
                if(couponInfo && couponInfo.productDiscount > 0){
                    total -=(getProductDiscountForCoupon(element.product, couponInfo.productDiscount)*product.count)
                }
            }
            console.log(element.product.name)
            console.log(total)
            if(total >= aboveNumber) return true
            return false
        }

        const getSendingCost = (productUser, address) => {
            if(!address) return "SELECCIONE DIRECCION"
            if(productUser.deliveryCosts && productUser.deliveryCosts.length > 0){
                for(const province of productUser.deliveryCosts){
                    if(province.provinceName === address.region){
                        if(province.available){
                            if(province.isFree?.always) return "GRATIS"
                            if(province.isFree?.above) {
                                const isAbove = checkAbovePassed(province.isFree.aboveNumber)
                                if(isAbove){
                                    return "GRATIS"
                                }
                                else{

                                }
                            }
                            for(const municipality of province.municipalities){
                                if(municipality.name === address.municipality){
                                    if(municipality.cost === 0) return "GRATIS"
                                    return `${municipality.cost} USD`
                                }
                            }
                        }
                        else{
                            return "NO ENTREGA EN SU PROVINCIA"
                        }
                    }
                }
            }
            return "GRATIS"
        }

        const getSendingCostTextAbove = (productUser, address) => {
            if(!address) return ""
            if(productUser.deliveryCosts && productUser.deliveryCosts.length > 0){
                for(const province of productUser.deliveryCosts){
                    if(province.provinceName === address.region){
                        if(province.available){
                            if(province.isFree?.always) return ""
                            if(province.isFree?.above) {
                                return `(COMPRA SUPERIOR A ${province.isFree.aboveNumber} USD ENTREGA GRATIS)`
                            }
                        }
                        else{
                            return ""
                        }
                    }
                }
            }
            return ""
        }

        return(
            <div>
                {firstDelivery &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            
                            <div style={{flex: 1}}>
                                <Typography className={classes.elementFirstHeader}>ENTREGA RAPIDA</Typography>
                            </div>
                            
                        </div>
                    </div>
                }
                {firstImport &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                <Typography className={classes.elementFirstHeader}>IMPORTACION</Typography>
                            </div>
                        </div>
                    </div>
                }
                {showUser &&
                    <div>
                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                            <Typography className={classes.elementHeader}>TIENDA:</Typography>
                            <img src={element.product.user.profilePictureUrl? element.product.user.profilePictureUrl : userPlaceholder} className={classes.userImage}/>
                            <Typography className={classes.elementHeader}>{element?.product?.user?.importCompanyName? element.product.user.importCompanyName.toUpperCase() : `${element.product?.user?.name?.toUpperCase()} ${element.product?.user?.lastName?.toUpperCase()}`}</Typography>
                        </div>
                    </div>
                }
                <React.Fragment>
                    <div className={classes.elementHolder}>
                        <div className={classes.imageContainer}>
                            <img alt="" className={classes.elementImage} src={element.product.mainImageUrl}/>
                        </div>
                        <div className={classes.elementInfoAndPrice}>
                            <div className={classes.elementInfo}>
                                <Typography className={classes.elementText}>{element.product.name.toUpperCase()}</Typography>
                                {(element.size && element.color) &&
                                <React.Fragment>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography className={classes.elementText} style={{fontSize: '11px', paddingRight: '5px'}}>TALLA:</Typography>
                                        <Typography className={classes.elementText} style={{fontSize: '13px', paddingLeft: '0px'}}>{element.size}</Typography>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography className={classes.elementText} style={{paddingRight: '5px', fontSize: '11px'}}>COLOR: </Typography>
                                        <div 
                                            style={{
                                                backgroundColor: element.color, 
                                                width: '17px', 
                                                height: '17px',
                                                borderRadius: '5px', 
                                                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                            }}
                                        />
                                    </div>    
                                </React.Fragment>
                                }
                                {(!element.product.available || element.product.deleted) && 
                                    <div style={{display: 'flex', marginTop: '5px', marginLeft: '15px'}}>
                                        <Typography style={{fontSize: '18px', fontWeight: '600', color: 'red'}}>(NO DISPONIBLE)</Typography>
                                    </div>
                                }
                                {element.product.fastDelivery && 
                                    <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}>
                                        <img src={b2_truck} style={{height: '12px', width: 'auto', marginTop: '4px', marginRight: '3px'}}/><Typography style={{fontSize: '12px', fontWeight: '600'}}>ENTREGA INMEDIATA</Typography>
                                    </div>
                                }
                                {element.deliveryCalendar?.allowed && 
                                    <div style={{padding: '0px 5px', marginTop: '10px', marginLeft: '10px'}}>
                                        <Typography style={{fontSize: '12px', fontWeight: '600'}}>FECHA/HORA ENTREGA</Typography>
                                        <Typography style={{fontSize: '11px', fontWeight: '600'}}>{getDeliveryCalendarString(element.deliveryCalendar)}</Typography>
                                        <Typography onClick={() => {handleOpenEditDeliveryCalendar(index)}} style={{fontSize: '10px', fontWeight: '600', color: 'orange', cursor: 'pointer', padding: '4px', width: '45px'}}>EDITAR</Typography>
                                    </div>
                                }
                            </div>
                            <div className={classes.priceInfo}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{display :'flex', alignItems: 'center'}}>
                                        <AttachMoney className={classes.leftInfoIconFilled}/>
                                        <Typography className={classes.leftInfoTag} style={{marginRight: '20px'}}>PRECIO</Typography>
                                    </div>
                                    <div>
                                        <Typography className={classes.rightInfoText} style={{fontSize: couponInfo? '14px' : '16px'}}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                                        {couponInfo && <Typography className={classes.rightInfoText} style={{fontSize: '13px'}}>-{`${getProductDiscountForCoupon(element.product, couponInfo.productDiscount)} `}<span className={classes.currencyPrice}>USD</span></Typography>}
                                        {couponInfo && <Typography className={classes.rightInfoText} style={{fontSize: '9px'}}>(DESC POR CUPON)</Typography>}  
                                        {couponInfo && <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product)-getProductDiscountForCoupon(element.product, couponInfo.productDiscount))} `}<span className={classes.currencyPrice}>USD</span></Typography>}      
                                    </div>
                                </div>
                                {/* {!element.product.fastDelivery &&
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                    <div style={{display :'flex', alignItems: 'center'}}>
                                        <Flight className={classes.leftInfoIconFilled}/>
                                        <Typography className={classes.leftInfoTag} style={{marginRight: '18px'}}>IMPORT</Typography>
                                    </div>
                                    <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(calcSendingCost(element.product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                                </div>
                                } */}
                                {/* <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                    <div style={{display :'flex', alignItems: 'center'}}>
                                        <Score className={classes.leftInfoIcon}/>
                                        <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>PUNTOS</Typography>
                                    </div>
                                    <Typography className={classes.rightInfoText}>{reduceDecimalValue(element.product.fastDelivery? 0 : element.product.points, 0, true)}</Typography>
                                </div> */}
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                    <Typography className={classes.deliveryTimeText}>ENT APROX: {getDeliveryTime(element.product)}</Typography>
                                </div>
                                
                                <div style={{marginTop: '25px'}}>
                                    <Typography style={{fontWeight: '600', fontSize: '13px', color: getAvailableColorText(element.size, element.color, element.product, element.count)? 'red' : 'inherit'}}>{getAvailableText(element.size, element.color, element.product)}</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{display:'flex', height: '25px'}}>
                                        {updatingCartIndexMinus === index?
                                            <div className={classes.minusCart}>
                                                <CircularProgress style={{color: colors.whiteAppbar, height: '14px', width: '14px', marginTop: '3px'}}/>
                                            </div>
                                            : 
                                            <Typography onClick={() => {removeFromCart(index)}} className={classes.minusCart}>-</Typography>
                                        }
                                        <Typography className={classes.cartCount}>{element.count}</Typography>
                                        {updatingCartIndexAdd === index?
                                            <div className={classes.sumCart}>
                                                <CircularProgress style={{color: colors.whiteAppbar, height: '14px', width: '14px', marginTop: '3px'}}/>
                                            </div>
                                            :
                                            <Typography onClick={() => {addToCart(index)}} className={classes.sumCart}>+</Typography>
                                        }
                                        
                                    </div>
                                    {deletingCartIndex === index?
                                        <CircularProgress style={{color: colors.secondary, height: '22px', width: '22px', marginLeft: '19px'}}/>
                                    :
                                        <DeleteForever onClick={() => {removeFromCart(index, element.count*(-1))}} className={classes.deleteButton}/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.countAndPriceHolder}>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                
                                {couponInfo?
                                    <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>{reduceDecimalValue((element.product.price - getProductDiscountForCoupon(element.product, couponInfo.productDiscount) + calcSendingCost(element.product))*element.count)} <span className={classes.currencyPrice}>USD</span></Typography>
                                    :
                                    <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>{reduceDecimalValue((element.product.price + calcSendingCost(element.product))*element.count)} <span className={classes.currencyPrice}>USD</span></Typography>
                                }
                            </div>
                        </div>
                        
                    </div>
                    <div className={classes.priceInfoResponsive}>
                        <div style={{flex: 1}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <AttachMoney className={classes.leftInfoIconFilled}/>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '10px'}}>PRECIO</Typography>
                                </div>
                                <div>
                                    <Typography className={classes.rightInfoText} style={{fontSize: couponInfo? '14px' : '16px'}}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                                    {couponInfo && <Typography className={classes.rightInfoText} style={{fontSize: '13px'}}>-{`${getProductDiscountForCoupon(element.product, couponInfo.productDiscount)} `}<span className={classes.currencyPrice}>USD</span></Typography>}
                                    {couponInfo && <Typography className={classes.rightInfoText} style={{fontSize: '9px'}}>(DESC POR CUPON)</Typography>}  
                                    {couponInfo && <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(element.product.price + calcSendingCost(element.product)-getProductDiscountForCoupon(element.product, couponInfo.productDiscount))} `}<span className={classes.currencyPrice}>USD</span></Typography>}      
                                </div>
                            </div>
                            {/* {!element.product.fastDelivery &&
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Flight className={classes.leftInfoIconFilled}/>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '8px'}}>IMPORT</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{`${reduceDecimalValue(calcSendingCost(element.product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                            </div>
                            } */}
                            {/* <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Score className={classes.leftInfoIcon}/>
                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '6px'}}>PUNTOS</Typography>
                                </div>
                                <Typography className={classes.rightInfoText}>{reduceDecimalValue(element.product.fastDelivery? 0 : element.product.points, 0, true)}</Typography>
                            </div> */}
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <Typography className={classes.deliveryTimeText}>ENT APROX: {getDeliveryTime(element.product)}</Typography>
                            </div>
                        </div>
                        <div style={{flex: 1, marginTop: '5px', alignItems: 'center'}}>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '15px'}}>
                                    <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>SUBTOTAL</Typography>
                                    {couponInfo?
                                        <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>{reduceDecimalValue((element.product.price - getProductDiscountForCoupon(element.product, couponInfo.productDiscount) + calcSendingCost(element.product))*element.count)} <span className={classes.currencyPrice}>USD</span></Typography>
                                        :
                                        <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>{reduceDecimalValue((element.product.price + calcSendingCost(element.product))*element.count)} <span className={classes.currencyPrice}>USD</span></Typography>
                                    }
                                </div>
                                <Typography style={{fontWeight: '600', fontSize: '13px', textAlign: 'center', color: getAvailableColorText(element.size, element.color, element.product, element.count)? 'red' : 'inherit'}}>{getAvailableText(element.size, element.color, element.product)}</Typography>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{display:'flex', height: '25px', marginLeft: 'auto', marginRight: 'auto'}}>
                                    {updatingCartIndexMinus === index?
                                        <div className={classes.minusCart}>
                                            <CircularProgress style={{color: colors.whiteAppbar, height: '14px', width: '14px', marginTop: '3px'}}/>
                                        </div>
                                        : 
                                        <Typography onClick={() => {removeFromCart(index)}} className={classes.minusCart}>-</Typography>
                                    }
                                    <Typography className={classes.cartCount}>{element.count}</Typography>
                                    {updatingCartIndexAdd === index?
                                        <div className={classes.sumCart}>
                                            <CircularProgress style={{color: colors.whiteAppbar, height: '14px', width: '14px', marginTop: '3px'}}/>
                                        </div>
                                        :
                                        <Typography onClick={() => {addToCart(index)}} className={classes.sumCart}>+</Typography>
                                    }
                                    
                                </div>
                                {/* <DeleteForever className={classes.deleteButton}/> */}
                                {deletingCartIndex === index?
                                    <CircularProgress style={{color: colors.secondary, height: '20px', width: '20px', marginLeft: '5px', marginTop: '8px'}}/>
                                :
                                    <Typography onClick={() => {removeFromCart(index, element.count*(-1))}} className={classes.deleteButton}>ELIMINAR</Typography>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                <div className={classes.lightGrayDividerElement} />
                {addSendingCost&&
                <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                    <img src={sendCostIcon} className={classes.airTaxImage}/>
                    <div style={{flex: 1}}>
                        <Typography className={classes.elementHeader}>COSTO DE ENTREGA</Typography>
                        {getSendingCost(element.product.user, address) !== "GRATIS" &&<Typography className={classes.elementFreeSendingCost}>{getSendingCostTextAbove(element.product.user, address)}</Typography>}
                        <Typography className={classes.elementSecondary}>TIENDA: {element?.product?.user?.importCompanyName? element.product.user.importCompanyName.toUpperCase() : `${element.product?.user?.name?.toUpperCase()} ${element.product?.user?.lastName?.toUpperCase()}`}</Typography>
                    </div>
                    <Typography className={classes.elementUnit}>{getSendingCost(element.product.user, address)}</Typography>
                </div>
                }
                
                {addSendingCost&&
                    <div className={classes.lightGrayDividerElement} style={{marginBottom: '50px'}}/>
                }
            </div>
        )
    }

    const updateCartProducts = async (quantity, productToUpdate) => {
        if(user){
            let product = productToUpdate.product
            try{
                let newCart = JSON.parse(JSON.stringify(importProducts));
                for (let index = 0; index < newCart.length; index++) {
                    if(newCart[index].product._id === product._id){
                        if(productToUpdate.size && productToUpdate.color){
                            if(newCart[index].size === productToUpdate.size && newCart[index].color === productToUpdate.color){
                                if(newCart[index].count + quantity !== 0)
                                {
                                    newCart[index].count += quantity
                                }
                                else{
                                    newCart.splice(index,1)
                                    let allFastDelivery = true
                                    newCart.forEach(element => {
                                        if(!element.product.fastDelivery){
                                            allFastDelivery = false
                                        }
                                    });
                                    if(allFastDelivery)setAirWaybill(0)
                                    else setAirWaybill(packaging+guideCut)
                                }
                                break;
                            }
                        }
                        else{
                            if(newCart[index].count + quantity !== 0)
                            {
                                newCart[index].count += quantity
                            }
                            else{
                                newCart.splice(index,1)
                                let allFastDelivery = true
                                newCart.forEach(element => {
                                    if(!element.product.fastDelivery){
                                        allFastDelivery = false
                                    }
                                });
                                if(allFastDelivery)setAirWaybill(0)
                                else setAirWaybill(packaging+guideCut)
                            }
                            break;
                        }
                    }
                }
                let requestOptions = {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
                    },
                    body:JSON.stringify({ 
                        cart: newCart,
                        quantity: quantity,
                        productId: productToUpdate.product._id
                    })
                };
                let urlGetProduct = `${url}/api/accountImport/updateCart`
                let result = await fetch(urlGetProduct, requestOptions)
                if(result.status === 200){
                    return {
                        ok: true,
                        products: newCart
                    }
                }
                else{
                    return { 
                        ok: false,
                        products: importProducts
                    }
                }
            }catch(error){
                console.log(error)
                return { 
                    ok: false,
                    products: importProducts
                }
            }
        }
    }

    const updateCartDeliveryCalendarProduct = async (productToUpdate) => {
        if(user){
            let product = productToUpdate.product
            try{
                let newCart = JSON.parse(JSON.stringify(importProducts));
                for (let index = 0; index < newCart.length; index++) {
                    if(newCart[index].product._id === product._id){
                        if(productToUpdate.size && productToUpdate.color){
                            if(newCart[index].size === productToUpdate.size && newCart[index].color === productToUpdate.color){
                                newCart[index].deliveryCalendar = {
                                    allowed: true,
                                    day: selectedDate.day,
                                    month: selectedDate.month,
                                    year: selectedDate.year,
                                    hours: selectedDate.hours,
                                    minutes: selectedDate.minutes,
                                    ampm: selectedDate.ampm
                                }
                                break;
                            }
                        }
                        else{
                            newCart[index].deliveryCalendar = {
                                allowed: true,
                                day: selectedDate.day,
                                month: selectedDate.month,
                                year: selectedDate.year,
                                hours: selectedDate.hours,
                                minutes: selectedDate.minutes,
                                ampm: selectedDate.ampm
                            }
                            break;
                        }
                    }
                }
                let requestOptions = {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
                    },
                    body:JSON.stringify({ 
                        cart: newCart,
                        quantity: 0,
                        productId: productToUpdate.product._id
                    })
                };
                let urlGetProduct = `${url}/api/accountImport/updateCart`
                let result = await fetch(urlGetProduct, requestOptions)
                if(result.status === 200){
                    return {
                        ok: true,
                        products: newCart
                    }
                }
                else{
                    return { 
                        ok: false,
                        products: importProducts
                    }
                }
            }catch(error){
                console.log(error)
                return { 
                    ok: false,
                    products: importProducts
                }
            }
        }
    }

    const addToCart = async (index) => {
        if(updatingCartIndexMinus !== -1 || updatingCartIndexAdd !== -1 || deletingCartIndex !== -1) return
        let products = importProducts
        if(!products[index].product.fastDelivery && products[index].count >= products[index].product.maxCount){
            setCartText(`Este producto tiene un máximo de ${products[index].product.maxCount} por pedido. Haga su pedido actual para poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        if(products[index].count >= products[index].product.availableCount){
            setCartText(`Solo tenemos ${products[index].product.availableCount} unidades disponibles de este producto.`)
            setOpenCartSnack(true)
            return
        }
        if(hasSizeAndColor(products[index].product.category._id)){
            let count = 0
            if(products[index].size && products[index].color){
                for(const t of products[index].product.availableCountByTags){
                    if(t.size === products[index].size && t.color === products[index].color){
                        count = t.availableCount
                        break;
                    }
                }
            }
            if(count > 0 && products[index].count >= count){
                setCartText(`Solo tenemos ${count} unidades disponibles de este producto en esa talla y color.`)
                setOpenCartSnack(true)
                return
            }
        }
        setUpdatingCartIndexAdd(index)
        const newProducts = await updateCartProducts(1, products[index])
        if(newProducts.ok){
            const newPoints = products[index].product.fastDelivery? cart_store_selector.points : cart_store_selector.points+products[index].product.points
            dispatch(update_cart_action(1, newProducts.products, newPoints))
        }
        else{
            setOpenCartErrorSnack(true)
        }
        setUpdatingCartIndexAdd(-1)
    }

    const editDeliveryCalendar = async () => {
        if(editingDeliveryCalendar) return
        if(updatingCartIndexMinus !== -1 || updatingCartIndexAdd !== -1 || deletingCartIndex !== -1) return
        if(selectedDate.day !== -1 &&
            selectedDate.month !== -1 &&
            selectedDate.year !== -1 &&
            selectedDate.hours !== -1 &&
            selectedDate.minutes !== -1
        ){
            const product = importProducts[updateDeliveryCalendarIndex].product
            const isValidCalendarD = isValidCalendarDate(selectedDate.day, selectedDate.month, selectedDate.year, product.deliveryCalendar.minHoursTime, product.deliveryCalendar.maxHoursTime, product.deliveryCalendar.maxTime)
            const currentDate = new Date()
            const sameDay = (currentDate.getDate() === selectedDate.day &&
                currentDate.getMonth() === selectedDate.month-1 &&
                currentDate.getFullYear() === selectedDate.year)
            const isValidCalendarT = isValidCalendarTime(selectedDate.hours, selectedDate.minutes, selectedDate.ampm, product.deliveryCalendar.minTime, product.deliveryCalendar.maxTime, sameDay, product.deliveryCalendar.minHoursTime)
            
            if(!isValidCalendarT.isValid){
                if(isValidCalendarT.reason === "Min Hours Time Needed"){
                    setDeliveryCalendarErrorText(`Si la entrega es hoy debe tener al menos ${product.deliveryCalendar.minHoursTime} horas de antelación.`)
                }
                else{
                    const minTime = product.deliveryCalendar.minTime
                    const maxTime = product.deliveryCalendar.maxTime
                    setDeliveryCalendarErrorText(`La hora debe estar entre las ${getTimeString(minTime.hours)}:${getTimeString(minTime.minutes)} ${minTime.ampm} y las ${getTimeString(maxTime.hours)}:${getTimeString(maxTime.minutes)} ${maxTime.ampm}`)
                }
                
                return
            }
            console.log(isValidCalendarD)
            if(!isValidCalendarD.isValid){
                setDeliveryCalendarErrorText("Fecha Incorrecta")
                return
                
            }
            
        }
        else{
            console.log("ENTRO")
            setDeliveryCalendarErrorText("Debe Seleccionar Fecha y Hora de Entrega")
            return
        }
        let products = importProducts
        setEditingDeliveryCalendar(true)
        const newProducts = await updateCartDeliveryCalendarProduct(products[updateDeliveryCalendarIndex])
        if(newProducts.ok){
            const newPoints = cart_store_selector.points
            dispatch(update_cart_action(0, newProducts.products, newPoints))
            setSnackProps({
                open: true,
                autoHideDuration: 2000,
                severity: 'success',
                text: 'Fecha/Hora Entrega Cambiado Correctamente'
            })
            setSelectedDate({
                day: -1,
                month: -1,
                year: -1,
                hours: -1,
                minutes: -1,
                ampm: "AM"
            })
            setUpdateDeliveryCalendarIndex(-1)
            setOpenEditDeliveryCalendar(false)
        }
        else{
            setOpenCartErrorSnack(true)
        }
        setEditingDeliveryCalendar(false)
    }

    const removeFromCart = async (index, count) => {
        if(updatingCartIndexMinus !== -1 || updatingCartIndexAdd !== -1 || deletingCartIndex !== -1) return
        let products = importProducts
        if(products[index] === 0){
            return
        }
        else{
            if(count){
                setDeletingCartIndex(index)
            }
            else{
                setUpdatingCartIndexMinus(index)
            }
            const newProducts = await updateCartProducts(count? count : -1, products[index])
            if(newProducts.ok){
                let mult = 1
                if(count) mult = count*-1
                const newPoints = products[index].product.fastDelivery? cart_store_selector.points : cart_store_selector.points-(products[index].product.points*mult)
                dispatch(update_cart_action(-1*mult, newProducts.products, newPoints))
            }
            else{
                setOpenCartErrorSnack(true)
            }
            setUpdatingCartIndexMinus(-1)
            setDeletingCartIndex(-1)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirmClose = () => {
        setOpenConfirm(false)
    }

    const handleCloseEditDeliveryCalendar = () => {
        if(editingDeliveryCalendar) return
        setUpdateDeliveryCalendarIndex(-1)
        setSelectedDate({
            day: -1,
            month: -1,
            year: -1,
            hours: -1,
            minutes: -1,
            ampm: "AM"
        })
        setOpenEditDeliveryCalendar(false)
    }

    const handleOpenEditDeliveryCalendar = (index) => {
        setUpdateDeliveryCalendarIndex(index)
        setSelectedDate({
            day: importProducts[index].deliveryCalendar.day,
            month: importProducts[index].deliveryCalendar.month,
            year: importProducts[index].deliveryCalendar.year,
            hours: importProducts[index].deliveryCalendar.hours,
            minutes: importProducts[index].deliveryCalendar.minutes,
            ampm: importProducts[index].deliveryCalendar.ampm
        })
        setOpenEditDeliveryCalendar(true)
    }

    const onClickConfirm = async () => {
        if(confirmingOrder)return
        try{
            if(!address){
                setSnackProps({
                    open: true,
                    autoHideDuration: 2000,
                    severity: 'warning',
                    text: 'Debe seleccionar una direccion de entrega'
                })
                return
            }
            if(selectedPaymentType === "SELECT ONE"){
                setSnackProps({
                    open: true,
                    autoHideDuration: 2000,
                    severity: 'warning',
                    text: 'Debe seleccionar un METODO DE PAGO'
                })
                setPaymentTypeError("DEBE SELECCIONAR UN METODO DE PAGO")
                setPaymentTypeErrorOpen(true)
                return
            }

            if(selectedPaymentType === "OnlineStripe" && selectedPaymentStripeCurrencies === "SELECCIONE UNA"){
                setSnackProps({
                    open: true,
                    autoHideDuration: 2000,
                    severity: 'warning',
                    text: 'Debe seleccionar una Moneda'
                })
                setPaymentStripeCurrenciesError("DEBE SELECCIONAR UNA MONEDA")
                setPaymentStripeCurrenciesErrorOpen(true)
                return
            }
            
            if(address.region !== "La Habana"){
                setSnackProps({
                    open: true,
                    autoHideDuration: 2000,
                    severity: 'error',
                    text: 'Por el momento solo e hacen entregas en La Habana, disculpe las molestias ocasionadas. Pronto abriran el resto de provincias'
                })
                return
            }
            
            if(notAvailableinProvince){
                setSnackProps({
                    open: true,
                    autoHideDuration: 2000,
                    severity: 'warning',
                    text: 'Tiene productos que la tienda no entrega en su provincia'
                })
                return
            }
            try{
                if(importProducts.length > 0){
                    setConfirmingOrder(true)
                    setOpenOtherInProcessSnack(true)
                    let order = {
                        user : user._id,
                        sender: {
                            name: user.name,
                            phone: user.phone,
                            ci: ""
                        },
                        addressee: {
                            name: address.name,
                            address: address.address + ', ' + address.municipality + ', ' + address.region,
                            phone: address.phone,
                            ci: address.ci,
                            region: address.region,
                            municipality: address.municipality
                        },
                        useWallet: useWallet,
                        paymentType: selectedPaymentType,
                        currencySelected: selectedPaymentType === "OnlineStripe"? selectedPaymentStripeCurrencies : 'usd' 
                    }
                    if(couponInfo) order.couponInfo = `${discountCoupon1}${discountCoupon2}${discountCoupon3}${discountCoupon4}${discountCoupon5}${discountCoupon6}`
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json',
                                    'Authorization': `${accessToken}`
                        },
                        body: JSON.stringify(
                            order  
                        )
                    };
                    let result = await fetch(`${url}/api/orders`, requestOptions);
                    if(check200FamilyStatus(result.status)){
                        const data = await result.json()
                        setOrderId(data.orderId)
                    }
                    else{
                        if(result.status === 400){
                            const data = await result.json()
                            if(data.errorType === 0){
                                dispatch(update_cart_action(0, data.cart, cart_store_selector.points))
                                setOpenOtherInProcessSnack(false)
                                setOpenNotAvailableSnack(true)
                                setConfirmingOrder(false)
                                return
                            }
                        }
                        setOpenOtherInProcessSnack(false)
                        setOpenApiErrorSnack(true)
                        setConfirmingOrder(false)
                        return
                    }
                }
                else{
                    setSnackProps({
                        open: true,
                        autoHideDuration: 5000,
                        severity: 'error',
                        text: 'Carrito vacio, añada algún producto a su carrito para poder hacer un pedido!'
                    })
                }
                
            }
            catch(e){
                console.log(e)
                setOpenOtherInProcessSnack(false)
                setConfirmingOrder(false)
                setOpenApiErrorSnack(true)
            }
        
        }
        catch(e){
            //console.log("ERROR")
            console.log(e)
            setOpenOtherInProcessSnack(false)
            setConfirmingOrder(false)
        }
    }

    const onConfirm = () => {
        setAdded(prev => prev+1)
        setOpenModal(false)
        setSnackProps(
            {
                open: true,
                autoHideDuration: 2000,
                text: "SE HA AGREGADO LA DIRECCION CORRECTAMENTE",
                severity: "success"
            }
        )
    }

    const onError = () => {
        setSnackProps(
            {
                open: true,
                autoHideDuration: 2000,
                text: "NO SE PUDO AGREGAR LA DIRECCION. REVISE SU CONEXION A INTERNET",
                severity: "error"
            }
        )
    }

    const onClose = () => {
        setOpenModal(false)
    }

    const handleCloseSnack = () => {
        setSnackProps(
            {
                ...snackProps,
                open: false
            }
        )
    }

    const handleAddClick = () => {
        setOpenModal({open: true, index: -1})
    }

    const isFirstImport = (index, products) => {
        if(index === 0 && !products[index].product.fastDelivery) return true
        if(index-1 >= 0 && !products[index].product.fastDelivery && products[index-1].product.fastDelivery) return true
        return false
    }
    const isFirstDelivery = (index, products) => {
        if(index === 0 && products[index].product.fastDelivery) return true
        return false 
    }
    const isSendingCostItem = (index, products) => {
        const count = products.length
        if(index === count - 1) return true
        if(products[index].product.user._id !== products[index+1].product.user._id) return true
        else{
            if(products[index].product.fastDelivery && !products[index+1].product.fastDelivery) return true
        }
        return false
    }

    const isShowUser = (index, products) => {
        if(index === 0) return true
        if(products[index].product.user._id !== products[index-1].product.user._id) return true
        else{
            if(!products[index].product.fastDelivery && products[index-1].product.fastDelivery) return true
        }
        return false
    }

    const toUpper = (text) => {
        if(text.length > 0) return text.toUpperCase()
        return ""
    }

    const getWalletUseInOrder = (totalProductsPriceA, totalCouponDiscountA, totalDeliveryCostA, walletA, useWalletA) => {
        if(walletA > 0 && useWalletA){
            if(wallet >= totalProductsPriceA+totalDeliveryCostA-totalCouponDiscountA){
                return reduceDecimalValue(totalProductsPriceA+totalDeliveryCostA-totalCouponDiscountA)
            }
            return reduceDecimalValue(walletA)
        }
        return reduceDecimalValue(0)
    }

    const getTotalCheckout = (productsPriceA, airWaybillA, totalDeliveryCostA, totalCouponDiscountA, walletA, useWalletA) => {
        let total = productsPriceA + airWaybillA + totalDeliveryCostA
        if(totalCouponDiscountA > 0){
            total -= totalCouponDiscountA
        }
        if(useWalletA && walletA > 0){
            if(walletA >= total){
                total = 0
            }
            else{
                total -= walletA
            }
        }
        return reduceDecimalValue(total)
    }

    const getPaymentButtonText = (pType, total, discount, tWallet, activatedWallet) => {
        // if(total !== 0 && tWallet !== 0 && activatedWallet){
        //     if(tWallet >= total - discount){
        //         return "CARTERA DIGITAL"
        //     }
        // }
        return getPaymentTypeName(pType)
        
    }

    const isCouponError = (text) => {
        if(text === "CUPON INCORRECTO") return true
        if(text === "NO PUEDE USAR SU PROPIO CUPON") return true
        return false
    }

    const checkDeliveryAddress = () => {
        if(!address){
            setSnackProps({
                open: true,
                autoHideDuration: 2000,
                severity: 'warning',
                text: 'Debe seleccionar una direccion de entrega'
            })
            return
        }
        setOpenConfirm(true)
    }

    return(
        <React.Fragment>
            <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                <Alert onClose={handleCloseApiErrorSnack} severity="error">
                    No se pudo crear el pedido. Revise su conexión a Internet.
                </Alert>
            </Snackbar>
            <Snackbar open={openCartSnack} autoHideDuration={5000} onClose={handleCloseCartSnack}>
                <Alert onClose={handleCloseCartSnack} severity="warning">
                    {cartText}
                </Alert>
            </Snackbar>
            <Snackbar open={openCartErrorSnack} autoHideDuration={3000} onClose={handleCloseCartErrorSnack}>
                <Alert onClose={handleCloseCartErrorSnack} severity="error">
                    No se pudo actualizar el carrito. Revise su conexión a internet.
                </Alert>
            </Snackbar>
            <Snackbar open={openOtherInProcessSnack} autoHideDuration={45000} onClose={handleCloseOtherInProcessSnack}>
                <Alert onClose={handleCloseOtherInProcessSnack} severity="info">
                    Creando Pedido
                </Alert>
            </Snackbar>
            <Snackbar open={openNotAvailableSnack} autoHideDuration={5000} onClose={handleCloseNotAvailableSnack}>
                <Alert onClose={handleCloseNotAvailableSnack} severity="error">
                    No se pudo crear pedido, hay uno o varios productos que no presentan la disponibilidad que usted solicita. Revise el carrito y elimine o reduzca la cantidad del producto o productos que tienen NO DISPONIBLE en rojo.
                </Alert>
            </Snackbar>
            <Snackbar open={openOtherSuccessSnack} autoHideDuration={2000} onClose={handleCloseOtherSuccessSnack}>
                <Alert onClose={handleCloseOtherSuccessSnack} severity="success">
                    PEDIDO CREADO
                </Alert>
            </Snackbar>
            <Snackbar open={openRedirectingSnack} autoHideDuration={1000} onClose={handleCloseRedirectingSnack}>
                <Alert onClose={handleCloseRedirectingSnack} severity="success">
                    REDIRECCIONANDO A PAGINA DE PEDIDOS
                </Alert>
            </Snackbar>
            <Snackbar open={openRedirectingStripeSnack} autoHideDuration={1000} onClose={handleCloseRedirectingStripeSnack}>
                <Alert onClose={handleCloseRedirectingStripeSnack} severity="success">
                    REDIRECCIONANDO A STRIPE PARA PAGAR PEDIDO
                </Alert>
            </Snackbar>
            <Snackbar open={snackProps.open} autoHideDuration={2000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={snackProps.severity}>
                    {snackProps.text}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="make order"
                aria-describedby="data necessary to process order"
            >
                <div className={classes.modalPaper}>
                    <IconButton size="large" onClick={handleClose} aria-label="warning" className={classes.cancelButton}>
                        <Cancel />
                    </IconButton> 
                    <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: '10px'}}>CAMBIAR DIRECCION DE ENTREGA</Typography>
                    <div>
                        {user?.deliveryAddresses?.length > 0 &&
                            user.deliveryAddresses.map((element, index) => {
                                return(
                                    <div onClick={() => {handleSelectAddress(index)}} style={{marginLeft: '10px', cursor: 'pointer', marginRight: '10px'}} key={index}>
                                        {element.isDefault && <Typography style={{paddingLeft: '5px'}}>(Predeterminada)</Typography>}
                                        <Typography className={classes.itemName}><Person style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{element.name}</Typography>
                                        <Typography><LocationOn style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{element.address}, {element.region}, {element.municipality}</Typography>
                                        <Typography><Phone style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{element.phone} <RecentActors style={{height: '15px', width: '15px', marginLeft: '5px', marginRight: '3px', paddingTop: '3px', color: colors.secondary, opacity: '0.8'}}/>{element.ci}</Typography>
                                        <div className={classes.division}/>
                                    </div>
                                ) 
                            })
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="condirm order"
                aria-describedby="data necessary to confirm order"
            >
                <div className={classes.modalPaper}>
                    <IconButton size="large" onClick={handleConfirmClose} aria-label="warning" className={classes.cancelButton}>
                        <Cancel />
                    </IconButton> 
                    <div style={{flex: 1, display: 'flex', color: colors.secondary, alignItems: 'center', marginTop: '5px'}}>
                        <Typography className={classes.checkOutPrimaryText} style={{marginRight: '0px', marginLeft: 'auto'}}>TOTAL</Typography>
                        <AttachMoney style={{marginLeft: '0px', color: colors.primary}} />
                        <Typography className={classes.checkOutPrimaryText}>{getTotalCheckout(totalPrice, airWaybill, totalDeliveryCost, totalCouponDiscount, wallet, useWallet)} USD</Typography>
                    </div>
                    <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: '5px', marginTop: '10px', textAlign: 'center'}}>METODO DE PAGO</Typography>
                    <div>
                        <div className={classes.ButtonHolder}>
                            <Button
                                variant="contained"
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handlePaymentTypeClick}
                                className={`${classes.paymentTypeButton}`}
                                classes={{
                                    label: classes.labelCategory,
                                    endIcon: classes.dropDownIcon // class name, e.g. `classes-nesting-label-x`
                                }}
                                endIcon={<ArrowDropDown />}
                            >
                                {getPaymentButtonText(selectedPaymentType, totalPrice, totalCouponDiscount, wallet, useWallet)}
                            </Button>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorElPaymentType}
                                keepMounted
                                open={openPaymentType}
                                onClose={() => handlePaymentTypeClose("-1")}
                                PaperProps={{
                                style: {
                                },
                                }}
                            >
                                {["SELECT ONE"].concat(paymentTypeSelectors).map((paymentType) => (
                                    <MenuItem key={paymentType} selected={paymentType === selectedPaymentType} onClick={() => handlePaymentTypeClose(paymentType)}>
                                        {getPaymentTypeName(paymentType)}
                                    </MenuItem>
                                ))}
                            </Menu>
                            {paymentTypeError &&
                                <div style={{position: 'absolute', right:'10px', top: '0px'}}>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                    arrow
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={handlePaymentTypeErrorTooltipClose}
                                    open={paymentTypeErrorOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={paymentTypeError}
                                    >
                                    <IconButton onClick={handlePaymentTypeErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                        <WarningAmberIcon fontSize="small" />
                                    </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                                </div>  
                            }
                        </div>
                        {selectedPaymentType === "OnlineStripe" &&
                        <React.Fragment>
                            <Typography className={classes.couponDiscountMainText} style={{fontSize: '11px'}}>
                                Stripe es una plataforma de pagos en línea que permite a las empresas y autónomos gestionar los pagos a través de un sitio web...
                            </Typography>
                            <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: '5px', marginTop: '10px', textAlign: 'center'}}>MONEDA DE SU TARJETA</Typography>
                            <div className={classes.ButtonHolder}>
                                <Button
                                    variant="contained"
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handlePaymentStripeCurrenciesClick}
                                    className={`${classes.paymentTypeButton}`}
                                    classes={{
                                        label: classes.labelCategory,
                                        endIcon: classes.dropDownIcon // class name, e.g. `classes-nesting-label-x`
                                    }}
                                    endIcon={<ArrowDropDown />}
                                >
                                    {selectedPaymentStripeCurrencies?.toUpperCase()}
                                </Button>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorElPaymentStripeCurrencies}
                                    keepMounted
                                    open={openPaymentStripeCurrencies}
                                    onClose={() => handlePaymentStripeCurrenciesClose("-1")}
                                    PaperProps={{
                                    style: {
                                    },
                                    }}
                                >
                                    {["SELECCIONE UNA"].concat(paymentStripeCurrencies).map((paymentStripeCurrency) => (
                                        <MenuItem key={paymentStripeCurrency} selected={paymentStripeCurrency === selectedPaymentStripeCurrencies} onClick={() => handlePaymentStripeCurrenciesClose(paymentStripeCurrency)}>
                                            {paymentStripeCurrency?.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                {paymentStripeCurrenciesError &&
                                    <div style={{position: 'absolute', right:'10px', top: '0px'}}>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handlePaymentStripeCurrenciesErrorTooltipClose}
                                        open={paymentStripeCurrenciesErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={paymentStripeCurrenciesError}
                                        >
                                        <IconButton onClick={handlePaymentStripeCurrenciesErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    </div>  
                                }
                            </div>
                        </React.Fragment>
                        }
                        <div style={{display: 'flex'}}>
                            <div style={{marginTop: '15px', maxWidth: '300px', marginLeft: 'auto'}}>
                                <Typography className={classes.couponDiscountMainText}>USAR SALDO DE MI CARTERA</Typography>
                                <Typography className={classes.couponDiscountSecondaryText}> DISPONIBLE: {reduceDecimalValue(wallet)} USD</Typography>
                                {/* <Typography className={classes.couponDiscountSecondaryText}>(SOLO APLICA A PRODUCTOS)</Typography> */}
                            </div>
                            <div style={{marginTop: '5px', marginRight: 'auto', paddingRight: '10px'}}>
                                <Checkbox
                                    disabled={!wallet}
                                    style={{padding:'9px 3px'}}
                                    checked={useWallet}
                                    onChange={handleUseWalletChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button onClick={onClickConfirm} className={classes.makeOrderButton} variant='contained'>
                            {confirmingOrder? 'CONFIRMANDO' : 'CONFIRMAR'} PEDIDO {confirmingOrder? <CircularProgress style={{marginLeft: '5px', color: colors.whiteAppbar, height: '20px', width: '20px'}}/> : ''}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={openEditDeliveryCalendar}
                onClose={handleCloseEditDeliveryCalendar}
                aria-labelledby="make order"
                aria-describedby="data necessary to change delivery calendar"
            >
                <div className={classes.modalPaper}>
                    <IconButton size="large" onClick={handleCloseEditDeliveryCalendar} aria-label="warning" className={classes.cancelButton}>
                        <Cancel />
                    </IconButton> 
                    <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: '10px'}}>CAMBIAR FECHA/HORA ENTREGA</Typography>
                    <div>
                        <div>
                            <Typography className={classes.itemName}>{importProducts[updateDeliveryCalendarIndex]?.product?.name}</Typography>
                            <PickCalendarProduct 
                                yearsMonthsDaysAvailable={yearsMonthsDaysAvailable} 
                                selectedDate={selectedDate}
                                newDateSetter={setSelectedDate}
                                formErrorText={deliveryCalendarErrorText}
                                formErrorTextSetter={setDeliveryCalendarErrorText} 
                            />
                        </div>        
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {editingDeliveryCalendar?
                            <Button className={classes.confirmButton} variant='contained'>
                                <CircularProgress size={20} style={{color: colors.secondary}}/>
                            </Button>
                        :
                            <Button onClick={editDeliveryCalendar} className={classes.confirmButton} variant='contained'>
                                CONFIRMAR
                            </Button>
                        }
                    </div>
                </div>
            </Modal>
            <Navbar cartPage/>
            <div className={classes.blueBackground}>
                <div className={classes.cartHolder}>
                    <div className={classes.cartCheckOut}>
                        <TitleWithBottomArrow title={"CHECKOUT"}/>
                        <div className={classes.checkOutHolder}>
                            <div style={{flex: 1}}>
                                {airWaybill > 0 &&
                                    <React.Fragment>
                                        <Typography className={classes.infoHeaderText}>INFO</Typography>
                                        <Typography className={classes.infoText}><span style={{fontWeight: '700'}}>-Productos Importación:</span> Se pagan al realizar el pedido</Typography>
                                    </React.Fragment>
                                }
                                
                                <Typography className={classes.totalText}>RESUMEN</Typography>
                                <div className={classes.divisionSummarize} />
                                <div style={{display: 'flex', color: colors.secondary, alignItems: 'center'}}>
                                    <div style={{flex: 1}}>
                                        <Typography className={classes.checkOutLeftSecondaryText}>PRODUCTOS</Typography>
                                    </div>
                                    <div style={{display: 'flex', flex: 1}}>
                                        <AttachMoney style={{width: '20px'}} />
                                        {/* <Flight style={{marginLeft: '-2px', marginRight: '8px'}}/> */}
                                        <Typography className={classes.checkOutSecondaryText}>{reduceDecimalValue(totalPrice)} USD</Typography>
                                    </div>
                                </div>
                                {totalCouponDiscount > 0 &&
                                    <div style={{display: 'flex', color: colors.secondary, alignItems: 'center'}}>
                                        <div style={{flex: 1}}>
                                            <Typography className={classes.checkOutLeftSecondarySmallText}>DESCUENTO</Typography>
                                        </div>
                                        <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                            <LocalOffer style={{marginLeft: '2px', width: '13px', marginRight: '5px'}} />
                                            <Typography className={classes.checkOutSecondarySmallText}>-{reduceDecimalValue(totalCouponDiscount)} USD</Typography>

                                        </div>
                                    </div>
                                }

                                <div className={classes.divisionSummarize}/>
                                <div style={{display: 'flex', marginTop: '10px', color: colors.secondary, alignItems: 'center'}}>
                                    <div style={{flex: 1}}>
                                        <Typography className={classes.checkOutLeftSecondaryText}>COSTO DE ENTREGA</Typography>
                                    </div>
                                    <div style={{display: 'flex', flex: 1}}>
                                        <LocalShipping style={{marginLeft: '3px', marginRight: '4px', width: '16px'}} />
                                        <Typography className={classes.checkOutSecondaryText}>{reduceDecimalValue(totalDeliveryCost)} USD</Typography>
                                    </div>
                                </div>
                                <div className={classes.divisionSummarize} />
                                {airWaybill > 0 &&
                                    <div>
                                        <div style={{display: 'flex', marginTop: '10px', color: colors.secondary, alignItems: 'center'}}>
                                            <div style={{flex: 1}}>
                                                <Typography className={classes.checkOutLeftSecondaryText}>IMPUESTO AEREO</Typography>
                                            </div>
                                            <div style={{display: 'flex', flex: 1}}>
                                                <Flight style={{marginLeft: '3px', marginRight: '3px', width: '16px'}} />
                                                <Typography className={classes.checkOutSecondaryText}>{reduceDecimalValue(airWaybill)} USD</Typography>
                                            </div>
                                        </div>
                                        <div className={classes.divisionSummarize}/>
                                    </div>
                                }
                                {(useWallet && wallet > 0) &&
                                    <div>
                                        <div style={{display: 'flex', color: colors.secondary, alignItems: 'center'}}>
                                            <div style={{flex: 1}}>
                                                <Typography className={classes.checkOutLeftSecondarySmallText}>CARTERA</Typography>
                                            </div>
                                            <div style={{flex: 1, display: 'flex', color: colors.secondary, alignItems: 'center'}}>
                                                <AccountBalanceWallet style={{marginLeft: '1px', width: '16px', marginRight: '5px'}} />
                                                <Typography className={classes.checkOutSecondarySmallText}>-{getWalletUseInOrder(totalPrice, totalCouponDiscount, totalDeliveryCost, wallet, useWallet)} USD</Typography>

                                            </div>
                                        </div>
                                        <div className={classes.divisionSummarize}/>
                                    </div>
                                }
                                <div style={{display: 'flex', color: colors.secondary, alignItems: 'center'}}>
                                    <div style={{flex: 1, display: 'flex', color: colors.secondary, alignItems: 'center', marginTop: '5px'}}>
                                        <Typography className={classes.checkOutPrimaryText} style={{marginRight: '0px', marginLeft: 'auto'}}>TOTAL</Typography>
                                        <AttachMoney style={{marginLeft: '0px', color: colors.primary}} />
                                        <Typography className={classes.checkOutPrimaryText}>{getTotalCheckout(totalPrice, airWaybill, totalDeliveryCost, totalCouponDiscount, wallet, useWallet)} USD</Typography>
                                    </div>
                                </div>
                            </div>
                            <div>
                                
                                
                                <div style={{marginTop: '15px', marginBottom: '15px'}}>
                                    <div style={{maxWidth: '312px', marginLeft: 'auto', marginRight: 'auto'}}>
                                        <Typography className={classes.couponDiscountMainText}>CUPON DESCUENTO USUARIO (OPCIONAL)</Typography>
                                    </div>
                                    <div style={{display:'flex', marginTop: '5px'}}>
                                        <div className={classes.inputVerificateHolder} style={{marginLeft: 'auto'}} >
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon1("")
                                                    }}
                                                name='disc-coup-1'
                                                placeholder='--'
                                                value={toUpper(discountCoupon1)}
                                                onChange={handleChangeDiscountCoupon1}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon2("")
                                                    }}
                                                name='disc-coup-2'
                                                placeholder='--'
                                                value={toUpper(discountCoupon2)}
                                                onChange={handleChangeDiscountCoupon2}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon3("")
                                                    }}
                                                name='disc-coup-3'
                                                placeholder='--'
                                                value={toUpper(discountCoupon3)}
                                                onChange={handleChangeDiscountCoupon3}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon4("")
                                                    }}
                                                name='disc-coup-4'
                                                placeholder='--'
                                                value={toUpper(discountCoupon4)}
                                                onChange={handleChangeDiscountCoupon4}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder}>
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon5("")
                                                    }}
                                                name='disc-coup-5'
                                                placeholder='--'
                                                value={toUpper(discountCoupon5)}
                                                onChange={handleChangeDiscountCoupon5}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                        <div className={classes.inputVerificateHolder} style={{marginRight: 'auto'}} >
                                            <InputBase
                                                elevation={4}
                                                onClick={() => 
                                                    {
                                                        if(couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR") return
                                                        setDiscountCoupon6("")
                                                    }}
                                                name='disc-coup-6'
                                                placeholder='--'
                                                value={toUpper(discountCoupon6)}
                                                onChange={handleChangeDiscountCoupon6}
                                                classes={{
                                                    root: classes.inputRootVerificate,
                                                    input: classes.inputInputVerificate,
                                                }}
                                                inputProps={{ 'aria-label': 'search', maxLength:'1' }}
                                            />
                                        </div>
                                    </div>
                                    {couponResultText &&<Typography className={classes.couponResultText} style={{color: isCouponError(couponResultText)? colors.redError : (couponResultText === "CUPON APLICADO"? colors.primary : colors.secondary)}}>{couponResultText} {couponResultText === "VERIFICANDO CUPON, ESPERE POR FAVOR"? <CircularProgress style={{marginLeft: '5px', color: colors.secondary, height: '14px', width: '14px'}}/> : ""}</Typography>}
                                </div>
                                <div>
                                    <Button onClick={checkDeliveryAddress} className={classes.makeOrderButton} variant='contained'>
                                        HACER PEDIDO
                                    </Button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className={classes.cartContainer}>
                        <AddDeliveryAddressModal open={openModal} index={-1} onConfirm={onConfirm} onError={onError} onClose={onClose}/>
                        <TitleWithBottomArrow title={"DIRECCION DE ENTREGA"}/>
                        <div style={{marginTop: '30px', marginBottom: '25px'}} className={classes.otherProductsContainer}>
                            <div className={classes.externalHolder}>
                                {address?
                                <React.Fragment>
                                    <div onClick={() => {setOpen(true)}} className={classes.addressInternalHolder}>
                                        <div style={{marginRight: '20px'}}>
                                            <svg className={classes.svg}>
                                                <polygon points="20,0 10,10, 0,0" className={classes.polygon} />
                                            </svg>
                                        </div>
                                        <div>
                                            {address.isDefault && <Typography style={{paddingLeft: '5px'}}>(Predeterminada)</Typography>}
                                            <Typography className={classes.itemName}><Person style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{address.name}</Typography>
                                            <Typography><LocationOn style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{address.address}, {address.region}, {address.municipality}</Typography>
                                            <Typography><Phone style={{height: '15px', width: '15px', marginRight: '3px', color: colors.secondary, opacity: '0.8'}}/>{address.phone} <RecentActors style={{height: '15px', width: '15px', marginLeft: '5px', marginRight: '3px', paddingTop: '3px', color: colors.secondary, opacity: '0.8'}}/>{address.ci}</Typography>
                                        </div>
                                    </div>
                                    <div className={classes.buttonInternalHolder}>
                                        <Typography style={{fontSize: '12px', paddingLeft: '15px', paddingRight: '15px'}}>TENGA EN CUENTA QUE LAS TARIFAS DE ENTREGA PUEDEN VARIAR SEGUN SU UBICACION</Typography>
                                        <Button onClick={handleAddClick} className={classes.addButton} variant='contained'>
                                            AGREGAR
                                        </Button>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={classes.addressInternalHolder} style={{cursor: 'text'}}>
                                        <Typography>NO TIENE DIRECCIONES DE ENTREGA GUARDADAS, PRESIONE EL BOTON AGREGAR </Typography>                                       
                                    </div>
                                    <div className={classes.buttonInternalHolder}>
                                        <Typography style={{fontSize: '12px', paddingLeft: '15px', paddingRight: '15px'}}>TENGA EN CUENTA QUE LAS TARIFAS DE ENTREGA PUEDEN VARIAR SEGUN SU UBICACION</Typography>
                                        <Button onClick={handleAddClick} className={classes.addButton} variant='contained'>
                                            AGREGAR
                                        </Button>
                                    </div>
                                </React.Fragment>
                                }
                                
                            </div>
                        </div>
                        <div style={{height: '30px', backgroundColor: colors.cartBlueBackground}}/>
                        <TitleWithBottomArrow title={"CARRITO"}/>
                        {importProducts.length > 0 &&
                        <div className={classes.otherProductsContainer}>
                            <div className={classes.subTotalContainer}>
                                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                                    <Typography style={{textAlign: 'end', marginRight: '20px', fontWeight: '600', color: colors.secondary, paddingRight: '10px'}}>SUBOTAL</Typography>
                                </div>
                                <div className={classes.lightGrayDividerElement} />
                            </div>

                            <div className={classes.otherProductsItems}>
                                <div>
                                    {importProducts.map((element, index) => {
                                        return elementCard(element, index, address, isSendingCostItem(index, importProducts), isShowUser(index, importProducts), isFirstImport(index, importProducts), isFirstDelivery(index, importProducts), importProducts)
                                    })}
                                    {(airWaybill > 0 || isDiscountOverTotalPrice) &&
                                    <React.Fragment>
                                        
                                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                                            <img src={airTaxImage} className={classes.airTaxImage}/>
                                            <div style={{flex: 1}}>
                                                <Typography className={classes.elementHeader}>IMPUESTO AEREO AIR WAYBILL</Typography>
                                                {/* <Typography className={classes.elementSecondary}>TOTAL PUNTOS MENOR A {allowedAduanaPoints/2/2} = {reduceDecimalValue((guideCut/2)/2, 2)}USD</Typography>
                                                <Typography className={classes.elementSecondary}>TOTAL DE PUNTOS ENTRE {allowedAduanaPoints/2/2} Y {allowedAduanaPoints/2} = {reduceDecimalValue(guideCut/2, 2)}USD</Typography>
                                                <Typography className={classes.elementSecondary}>TOTAL PUNTOS MAYOR A {allowedAduanaPoints/2} = {reduceDecimalValue(guideCut, 2)}USD</Typography> */}
                                                {/* <Typography className={classes.elementSecondary}>{discountOverTotalPriceMessage.toUpperCase()} = GRATIS</Typography> */}
                                            </div>
                                            <Typography className={classes.elementUnit}>{reduceDecimalValue(airWaybill, 2)} USD</Typography>
                                        </div>
                                        {wallet === 0 && <div className={classes.lightGrayDividerElement} />}
                                    </React.Fragment>
                                    }
                                    {(useWallet && wallet > 0) &&
                                    <React.Fragment>
                                        <div className={classes.lightGrayDividerElement} />
                                        <div style={{display: 'flex', marginRight: '20px', paddingTop: '5px', paddingBottom: '10px', alignItems: 'center'}}>
                                            <img src={walletImage} className={classes.walletImage}/>
                                            <div style={{flex: 1, marginLeft: '-5px'}}>
                                                <Typography className={classes.elementHeader}>USO DE SALDO DE MI CARTERA</Typography>
                                            </div>
                                            <Typography className={classes.elementUnit}>-{getWalletUseInOrder(totalPrice, totalCouponDiscount, totalDeliveryCost, wallet, useWallet)} USD</Typography>
                                        </div>
                                        <div className={classes.lightGrayDividerElement} />
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                        {(importProducts.length === 0) &&
                            [cart_store_selector.initLoading?
                                <div className={classes.emptyCartContainer}>
                                    <CircularProgress style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', marginBottom: '10px', height: '50px', width: '50px'}}/>
                                    <Typography className={classes.emptyCartPrimaryText}>Cargando Carrito...</Typography>
                                </div>
                            :
                            <div className={classes.emptyCartContainer}>
                                <RemoveShoppingCart className={classes.emptyCartIcon}/>
                                <Typography className={classes.emptyCartPrimaryText}>Carrito Vacío</Typography>
                                <Typography className={classes.emptyCartSecondaryText}>Agregue un Producto y aparecerá aquí :)</Typography>
                            </div>
                            ]
                        }
                    </div>
                    
                </div> 
                {/* <div className={classes.favoritesList}>
                    <div className={classes.favoriteListHolder}>
                        <FavoritesPageProductList />
                    </div>
                </div>  */}
            </div>
            <Footer hideXs={true} />
        </React.Fragment>
    )
}


export default CartPage