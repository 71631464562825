import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update_cart_action } from '../../redux/actions/cartAction';
import url, { colors, appShareUrl } from '../../config';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../assets/img/placeholder.png';
import userPlaceholder from '../../assets/img/userPlaceholder.png';
import { RWebShare } from 'react-web-share'
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alpha, Button, CircularProgress, Modal, Snackbar, Switch } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import DeleteProductModal from './DeleteProductModal';
import LinesEllipsis from 'react-lines-ellipsis';
import { useEffect } from 'react';
import { AttachMoney, Cancel, Close, DirectionsBoat, FitnessCenter, Flight, LocalShipping, Shop, ShoppingCartOutlined, Stars, VerticalAlignTop, Visibility } from '@material-ui/icons';
import { LocalOffer } from '@material-ui/icons';
import { update_login_action } from '../../redux/actions/loginAction';
import reduceDecimalValue, { calcSendingCost, getDeliveryTime, getYearsMonthsDaysAvailable, isValidCalendarDate, isValidCalendarTime } from '../../tools/productTools';
import hasSizeAndColor from '../../tools/tagsTools';
import PickCalendarProduct from './PickCalendarProduct';
import { getTimeString } from '../../tools/userTools';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    productCard: {
        borderRadius: '7px',
    },
    productCardDetail: {
        borderRadius: '3px',
        backgroundColor: "#F1F1F1"
    },
    root: {
        maxWidth: '100%',
    },
    media: {
        width: '100%',
        aspectRatio: '1',
        objectFit: 'cover'
    },
    mediaPlaceholder: {
        width: '100%',
        aspectRatio: '1'
    },
    title:{
        color: '#1D1D1D',
        fontSize: '13px',
        fontWeight: '800',
        marginBottom: '0px',
        marginTop: '-10px',
        paddingLeft: '15px',
        paddingRight: '15px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '12px',
        }
    },
    description:{
        color: '#1D1D1D',
        fontSize: '11px',
        fontWeight: '600',
        marginTop: '5px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
        }
    },
    price:{
        color: colors.secondary,
        fontSize: '15px',
        padding: '12px 0px 2px 0px',
        fontWeight: '700',
        marginBottom: '0px'
    },
    sendingCost:{
        color: colors.secondary,
        opacity: '0.9',
        fontSize: '13px',
        marginTop: '-2px',
        fontWeight: '700',
        marginBottom: '2px'
    },
    points:{
        fontSize: '12px',
        fontWeight: '600',
        marginBottom: '-15px'
    },
    leftInfoIcon: {
        height: '20px'
    },
    leftInfoIconFilled: {
        height: '14px',
        width: '15px',
        padding: '0px 1px',
        margin: '1px 4.5px',
        opacity: '0.87',
        backgroundColor: 'black',
        color: colors.whiteAppbar,
        borderRadius: '2px'
    },
    leftInfoTag: {
        fontSize: '10px',
        fontWeight: '700'
    },
    rightInfoText: {
        fontSize: '16px',
        fontWeight: '700'
    },
    deliveryTimeText: {
        fontSize: '11px',
        fontWeight: '600',
        paddingLeft: '5px',
        textAlign: 'left',
        width: '100%',
        marginBottom: '-15px',
        marginTop: '5px'
    },
    deliveryTimeTextDetailPage: {
        fontSize: '11px',
        fontWeight: '600',
        paddingLeft: '5px',
        textAlign: 'left',
        width: '100%',
        marginBottom: '-15px',
        marginTop: '10px'
    },
    rightInfoTextDetailPage: {
        fontSize: '16px',
        fontWeight: '700',
        marginLeft: 'auto'
    },
    currencyPrice: {
        fontSize: '14px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '13px'
        }
    },
    productSecondaryInfo: {
        fontSize: '11px',
        fontWeight: '600',
    },
    group: {
        width: '100%',
        borderTop: `0.45px solid #E6E6E6`,
        marginLeft: '5px',
        paddingTop: '5px',
        justifyContent: 'end',
    },
    favorite: {
        color: colors.secondary,
        padding: '3px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     favoriteOn: {
        color: colors.primary,
        padding: '3px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     addCart: {
        color: colors.secondary,
        padding: '3px',
        '&:hover': {
            color: colors.greenCart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
    editIcon: {
        color: colors.orangeEdit,
        padding: '3px',
        '&:hover': {
            color: colors.orangeEdit
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    deleteIcon: {
        color: colors.redError,
        padding: '3px',
        '&:hover': {
            color: colors.redError
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    share: {
        color: colors.secondary,
        padding: '3px',
        marginRight: '2px',
        '&:hover': {
            color: colors.blueShare
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
            marginRight: '2px',
        }
    },
    promoIcon: {
        position: 'absolute',
        width: '60px !important',
        height: '60px',
        // backgroundColor: alpha('#f9f9f9',0.5),
        padding: '5px',
        borderRadius: '10px',
        // borderTopLeftRadius: '10px',
        // borderBottomRightRadius: '10px',
        right: '0px'
    },
    minusCart: { 
        border: `solid 2.3px ${colors.secondary}`,
        marginLeft: '5px',
        width: '30px',
        borderRight: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '17px',
        fontWeight: '800',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        },
    },
    sumCart: {
        border: `solid 2.3px ${colors.secondary}`,
        width: '30px',
        borderLeft: `solid 1.5px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '20px',
        fontWeight: '800',
        userSelect: 'none',
        color: colors.whiteAppbar,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.7)
        }
    },
    cartCount: {
        borderTop: `solid 2.3px ${colors.cartProductCountBackground}`,
        borderBottom: `solid 2.3px ${colors.cartProductCountBackground}`,
        backgroundColor: colors.cartProductCountBackground,
        fontSize: '14px',
        paddingLeft: '12px',
        paddingRight: '12px',
        textAlign: 'center',
        lineHeight: '22px',
        fontWeight: '700',
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: 400,
        borderRadius: '3px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainText: { 
        fontWeight: '600', 
        fontSize: '18px',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px'
        }
    },
    addCartButton: {
        color: 'white',
        backgroundColor: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        borderRadius: '3px',
        maxHeight: '35px',
        width: '180px',
        '&:hover': {
            backgroundColor: alpha(colors.secondary,0.8)
        }
    },
    buyButton: {
        color: 'white',
        backgroundColor: colors.primary,
        border: `solid 1.5px ${colors.primary}`,
        borderRadius: '3px',
        maxHeight: '35px',
        marginTop: '5px',
        width: '140px',
        marginLeft: 'auto',
        '&:hover': {
            backgroundColor: alpha(colors.primary,0.8)
        }
    },
    backButton: {
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        '&:hover': {
            backgroundColor: alpha(colors.secondary,0.2)
        }
    },
    checkIcon: {
    width: '28px',
    height: '28px',
    },
    fastDeliveryIcon: {
        height: '22px',
        color: colors.whiteAppbar,
        width: 'auto',
        marginRight: '3px',
        marginBottom: '0px'
    },
    fastDeliveryText: {
        fontSize: '11px', 
        fontWeight: '600',
        color: colors.whiteAppbar,
        [theme.breakpoints.down('xs')]: {
            fontSize: '9px', 
            fontWeight: '700',
        }
    },
    productPromotedTag: {
        display:'flex', 
        alignItems: 'center', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.productPromotedTag,
        width: '100%'
    },
    productPromotedTagDetailPage: {
        display:'flex', 
        alignItems: 'center', 
        paddingTop: '1px', 
        paddingBottom: '1px',  
        width: '100%',
        marginLeft: '12px'
    },
    possibleDiscountTag: {
        display:'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.possibleDiscountTag,
        width: '100%'
    },
    productFastDeliveryTag: {
        display:'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.fastDeliveryTag, 
        width: '100%'
    },
    unavailableTag: {
        display:'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.redError, 
        width: '100%'
    },
    unavailableTagDetailPage: {
        display:'flex', 
        alignItems: 'center', 
        marginBottom: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.redError, 
        width: '100%'
    },
    productImportTag: {
        display:'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.importTag, 
        width: '100%'
    },
    productImportBySeaTag: {
        display:'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        paddingTop: '3px', 
        paddingBottom: '3px', 
        justifyContent: 'center', 
        backgroundColor: colors.importTagBySea, 
        width: '100%'
    },
    userProduct: {
        height: '30px', 
        width: '30px !important',
        marginBottom: '4px',
        marginLeft: '5px',
        borderRadius: '3px',
        objectFit: 'contain',
        cursor: 'pointer'
    }
}));

export default function ProductCard({product, isFavorite, fromFavorites, updateFromFavorites, userId, reloadMyProducts, homePage, detailPage}) {
    const classes = useStyles()
    const history = useHistory();
    const [cartCount, setCartCount] = useState(1)
    const [onCart, setOnCart] = useState(0)
    const [cartText, setCartText] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [isProductFavorite, setIsProductFavorite] = useState(isFavorite)
    const [addingFavorite, setAddingFavorite] = useState(false)
    const [openCartSnack, setOpenCartSnack] = useState(false)
    const [openLoginNeededSnack, setOpenLoginNeededSnack] = useState(false)
    const [openDeleteSuccessSnack, setOpenDeleteSuccessSnack] = useState(false)
    const [openDeleteApiErrorSnack, setOpenDeleteApiErrorSnack] = useState(false)
    const [availableChecked, setAvailableChecked] = React.useState(product.available)
    const [availableLoading, setAvailableLoading] = React.useState(false)
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openCartSuccessSnack, setOpenCartSuccessSnack] = useState(false)
    const [openCartErrorSnack, setOpenCartErrorSnack] = useState(false)
    const [updatingCart, setUpdatingCart] = useState(false)

    const [sizes, setSizes] = useState([])
    const [productColors, setProductColors] = useState([])
    const [sizeSelected, setSizeSelected] = useState("")
    const [colorSelected, setColorSelected] = useState("")

    const [selectedDate, setSelectedDate] = useState({
        day: -1,
        month: -1,
        year: -1,
        hours: -1,
        minutes: -1,
        ampm: "AM"
    })

    const [yearsMonthsDaysAvailable, setYearsMonthsDaysAvailable] = useState([])
    const [deliveryCalendarErrorText, setDeliveryCalendarErrorText] = useState("")

    const userLogged = useSelector((state) => state.user_store.user)
    const accessToken = useSelector((state) => state.user_store.accessToken)
    const cart_store_selector = useSelector((state) => state.cart_store)


    useEffect(() => {
        if(userLogged){
            let founded = false
            if(cart_store_selector.products.length > 0){
                for (let index = 0; index < cart_store_selector.products.length; index++) {
                    if(cart_store_selector.products[index].product._id === product._id){
                        if(hasSizeAndColor(product.category._id)){
                            if(cart_store_selector.products[index].size === sizeSelected && cart_store_selector.products[index].color === colorSelected){
                                setOnCart(cart_store_selector.products[index].count)
                                founded = true
                                break;
                            }
                        }
                        else{
                            setOnCart(cart_store_selector.products[index].count)
                            founded = true
                            break;
                        }
                    }
                }
            }
            if(!founded){
                setOnCart(0)
            }
        }
    }, [sizeSelected, colorSelected])

    useEffect(() => {
        if(userLogged){
            if(hasSizeAndColor(product.category._id)){
                let sizesAvailable = []
                let colorsAvailable = []
                for(const t of product.availableCountByTags){
                    if(t.availableCount > 0){
                        if(!sizesAvailable.includes(t.size)) sizesAvailable.push(t.size)
                        if(!colorsAvailable.includes(t.color)) colorsAvailable.push(t.color)
                    }
                }
                if(sizesAvailable.length > 0 && colorsAvailable.length > 0){
                    setSizes(sizesAvailable)
                    setProductColors(colorsAvailable)
                    setSizeSelected(sizesAvailable[0])
                    setColorSelected(colorsAvailable[0])
                } 
            }
        }
    }, [])

    const dispatch = useDispatch()

    useEffect(() => {
        try{
            if(product?.deliveryCalendar?.allowed){
                let yearsMonthsDays = getYearsMonthsDaysAvailable(product)
                if(yearsMonthsDays.length > 0){
                    if(yearsMonthsDays[0].months.length > 0){
                        setSelectedDate({
                            ...selectedDate,
                            year: yearsMonthsDays[0].year,
                            month: yearsMonthsDays[0].months[0].month
                        })
                    }
                    else{
                        setSelectedDate({
                            ...selectedDate,
                            year: yearsMonthsDays[0].year
                        })
                    }
                }
                setYearsMonthsDaysAvailable(yearsMonthsDays)
            }
        }
        catch(e){
            console.log(e)
        }
    }, [])

    const mediaDisplay = () => {
        setLoaded(true)
    }

    const handleClose = () => {
        setCartCount(1)
        setOpenModal(false)
    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const OpenDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(true)
        setOpenDeleteModal(false)
    }
    const OpenDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(true)
    }

    const AddToFavorite = (event) => {
        if(addingFavorite)return;
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`importFavorites`)
            if(data){
                const newData = JSON.parse(data)
                if(newData[`${userId}`])
                {
                    newData[`${userId}`][`${product._id}`] = {
                        ...product,
                        user: product.user
                    }
                    window.localStorage.setItem(
                        `importFavorites`, JSON.stringify(newData)
                    )
                }
                else{
                    newData[`${userId}`] = {}
                    newData[`${userId}`][`${product._id}`] = {
                        ...product,
                        user: product.user
                    }
                    window.localStorage.setItem(
                        `importFavorites`, JSON.stringify(newData)
                    )
                }
                setAddingFavorite(false)
                setIsProductFavorite(true)
            }
            else{
                let newData = {}
                newData[`${userId}`] = {}
                newData[`${userId}`][`${product._id}`] = {
                    ...product,
                    user: product.user
                }
                window.localStorage.setItem(
                    `importFavorites`, JSON.stringify(newData)
                )
                setAddingFavorite(false)
                setIsProductFavorite(true)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
        
    }

    const RemoveFromFavorite = () => {
        if(addingFavorite)return;
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`importFavorites`)
            if(data){
                const newData = JSON.parse(data)
                if(Object.keys(newData[`${userId}`]).length === 1){
                    if(Object.keys(newData).length === 1)
                    {
                        window.localStorage.removeItem(
                            `importFavorites`, JSON.stringify(newData)
                        )
                    }
                    else{
                        delete newData[`${userId}`][`${product._id}`]    
                        window.localStorage.setItem(
                            `importFavorites`, JSON.stringify(newData)
                        )    
                    }
                }
                else{
                    if(newData[`${userId}`][`${product._id}`]){
                        delete newData[`${userId}`][`${product._id}`]
                    }
                    window.localStorage.setItem(
                        `importFavorites`, JSON.stringify(newData)
                    )
                }
                if(fromFavorites){
                    updateFromFavorites()
                }
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
            else{
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }

    const onClickAddToCart = () => {
        if(!userLogged) {
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
            return;
        }
        if(!product.fastDelivery && cartCount + onCart > product.maxCount){
            setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        if(cartCount + onCart > product.availableCount){
            setCartText(`Solo tenemos ${product.availableCount} unidades disponibles de este producto.`)
            setOpenCartSnack(true)
            return
        }
        // if(!product.fastDelivery && cart_store_selector.points + (product.points) > allowedAduanaPoints){
        //     setCartText(`No puede exceder los ${allowedAduanaPoints} puntos permitidos por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        //     setOpenCartSnack(true)
        //     return
        // }
        setOpenModal(true)
    }

    const addToCart = () => {
        if(updatingCart){
            return;
        }
        if(!userLogged) {
            setOpenLoginNeededSnack()
            dispatch(update_login_action(true))
            return;
        }
        if(!product.fastDelivery && cartCount + onCart >= product.maxCount){
            setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        if(cartCount + onCart >= product.availableCount){
            setCartText(`Solo tenemos ${product.availableCount} unidades disponibles de este producto.`)
            setOpenCartSnack(true)
            return
        }
        if(hasSizeAndColor(product.category._id)){
            let count = 0
            if(colorSelected && sizeSelected){
                for(const t of product.availableCountByTags){
                    if(t.size === sizeSelected && t.color === colorSelected){
                        count = t.availableCount
                        break;
                    }
                }
            }
            if(count > 0 && cartCount + onCart >= count){
                setCartText(`Solo tenemos ${count} unidades disponibles de este producto en esa talla y color.`)
                setOpenCartSnack(true)
                return
            }
        }
        setCartCount(cartCount+1)
    }

    const onClickAddConfirm = async (goToShop) => {
        if(product?.deliveryCalendar?.allowed){
            if(selectedDate.day !== -1 &&
                selectedDate.month !== -1 &&
                selectedDate.year !== -1 &&
                selectedDate.hours !== -1 &&
                selectedDate.minutes !== -1
            ){
                const isValidCalendarD = isValidCalendarDate(selectedDate.day, selectedDate.month, selectedDate.year, product.deliveryCalendar.minHoursTime, product.deliveryCalendar.maxHoursTime, product.deliveryCalendar.maxTime)
                const currentDate = new Date()
                const sameDay = (currentDate.getDate() === selectedDate.day &&
                    currentDate.getMonth() === selectedDate.month-1 &&
                    currentDate.getFullYear() === selectedDate.year)
                const isValidCalendarT = isValidCalendarTime(selectedDate.hours, selectedDate.minutes, selectedDate.ampm, product.deliveryCalendar.minTime, product.deliveryCalendar.maxTime, sameDay, product.deliveryCalendar.minHoursTime)
                console.log(isValidCalendarT)
                if(!isValidCalendarT.isValid){
                    if(isValidCalendarT.reason === "Min Hours Time Needed"){
                        setDeliveryCalendarErrorText(`Si la entrega es hoy debe tener al menos ${product.deliveryCalendar.minHoursTime} horas de antelación.`)
                    }
                    else{
                        const minTime = product.deliveryCalendar.minTime
                        const maxTime = product.deliveryCalendar.maxTime
                        setDeliveryCalendarErrorText(`La hora debe estar entre las ${getTimeString(minTime.hours)}:${getTimeString(minTime.minutes)} ${minTime.ampm} y las ${getTimeString(maxTime.hours)}:${getTimeString(maxTime.minutes)} ${maxTime.ampm}`)
                    }
                    
                    return
                }
                console.log(isValidCalendarD)
                if(!isValidCalendarD.isValid){
                    setDeliveryCalendarErrorText("Fecha Incorrecta")
                    return
                    
                }
                
            }
            else{
                setDeliveryCalendarErrorText("Debe Seleccionar Fecha y Hora de Entrega")
                return
            }
        }
        if(!product.fastDelivery && cartCount + onCart > product.maxCount){
            setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        if(cartCount + onCart > product.availableCount){
            setCartText(`Solo tenemos ${product.availableCount} unidades disponibles de este producto.`)
            setOpenCartSnack(true)
            return
        }
        if(cartCount + onCart > product.availableCount){
            setCartText(`Solo tenemos ${product.availableCount} unidades disponibles de este producto.`)
            setOpenCartSnack(true)
            return
        }
        if(hasSizeAndColor(product.category._id)){
            let count = 0
            if(colorSelected && sizeSelected){
                for(const t of product.availableCountByTags){
                    if(t.size === sizeSelected && t.color === colorSelected){
                        count = t.availableCount
                        break;
                    }
                }
            }
            if(count > 0 && cartCount + onCart > count){
                setCartText(`Solo tenemos ${count} unidades disponibles de este producto en esa talla y color.`)
                setOpenCartSnack(true)
                return
            }
        }
        setUpdatingCart(true)
        const newPoints = product.fastDelivery? cart_store_selector.points : cart_store_selector.points+(product.points*cartCount)
        const newProducts = await updateCartProducts(cartCount)
        if(newProducts.length > 0){
            dispatch(update_cart_action(cartCount, newProducts, newPoints))
            setOpenCartSuccessSnack(true)
            setOpenModal(false)
            setOnCart(cartCount + onCart)
            setCartCount(1)
        }
        else{
            setOpenCartErrorSnack(true)
        }
        setUpdatingCart(false)
        if(goToShop){
            window.scrollTo(0,0)
            history.push("/cart")
        }
    }

    const removeFromCart = () => {
        if(updatingCart){
            return;
        }
        if(!userLogged) {
            setOpenLoginNeededSnack()
            dispatch(update_login_action(true))
            return;
        }
        if(cartCount === 1){
            return
        }
        else{
            setCartCount(cartCount-1)
        }
    }

    const updateCartProducts = async (quantity) => {
        if(userLogged){
            try{
                let newCart = JSON.parse(JSON.stringify(cart_store_selector.products))
                let founded = false
                for (let index = 0; index < newCart.length; index++) {
                    if(newCart[index].product._id === product._id){
                        if(hasSizeAndColor(product.category._id)){
                            if(newCart[index].size === sizeSelected && newCart[index].color === colorSelected){
                                newCart[index].count += quantity
                                if(product?.deliveryCalendar?.allowed){
                                    newCart[index].deliveryCalendar = {
                                        allowed: true,
                                        day: selectedDate.day,
                                        month: selectedDate.month,
                                        year: selectedDate.year,
                                        hours: selectedDate.hours,
                                        minutes: selectedDate.minutes,
                                        ampm: selectedDate.ampm
                                    }
                                }
                                founded = true
                                break;
                            }
                        }
                        else{
                            newCart[index].count += quantity
                            if(product?.deliveryCalendar?.allowed){
                                newCart[index].deliveryCalendar = {
                                    allowed: true,
                                    day: selectedDate.day,
                                    month: selectedDate.month,
                                    year: selectedDate.year,
                                    hours: selectedDate.hours,
                                    minutes: selectedDate.minutes,
                                    ampm: selectedDate.ampm
                                }
                            }
                            founded = true
                            break;
                        }
                    }
                }
                if(!founded){
                    if(hasSizeAndColor(product.category._id)){
                        let cartElement = {
                            product: product, 
                            count: quantity, 
                            size: sizeSelected, 
                            color: colorSelected
                        }
                        if(product?.deliveryCalendar?.allowed){
                            cartElement.deliveryCalendar = {
                                allowed: true,
                                day: selectedDate.day,
                                month: selectedDate.month,
                                year: selectedDate.year,
                                hours: selectedDate.hours,
                                minutes: selectedDate.minutes,
                                ampm: selectedDate.ampm
                            }
                        }
                        newCart.push(cartElement)
                    }
                    else{
                        let cartElement = {
                            product: product, 
                            count: quantity
                        }
                        if(product?.deliveryCalendar?.allowed){
                            cartElement.deliveryCalendar = {
                                allowed: true,
                                day: selectedDate.day,
                                month: selectedDate.month,
                                year: selectedDate.year,
                                hours: selectedDate.hours,
                                minutes: selectedDate.minutes,
                                ampm: selectedDate.ampm
                            }
                        }
                        newCart.push(cartElement)
                    }
                }
                let requestOptions = {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`
                    },
                    body:JSON.stringify({ 
                        cart: newCart,
                        quantity: quantity,
                        productId: product._id
                    })
                };
                let urlGetProduct = `${url}/api/accountImport/updateCart`
                let result = await fetch(urlGetProduct, requestOptions)
                if(result.status === 200){
                    return newCart
                }
                else{
                    console.log(result)
                    return cart_store_selector.products
                }
                }catch(error){
                    console.log(error)
                    return cart_store_selector.products
                }
            }
            else{
                setOpenLoginNeededSnack(true)
                dispatch(update_login_action(true))
            }
    }

    const handleCloseLoginNeededSnack = () => {
        setOpenLoginNeededSnack(false)
        dispatch(update_login_action(true))
    }

    const handleCloseCartSnack = () => {
        setOpenCartSnack(false)
    }

    const handleCloseSuccessCartSnack = () => {
        setOpenCartSuccessSnack(false)
    }

    const handleCloseCartErrorSnack = () => {
        setOpenCartErrorSnack(false)
    }

    const handleCloseDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(false)
        reloadMyProducts()
    }

    const handleCloseDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(false)
    }

    const productOnClick = (event) => {
        history.push(`/products/detail/${product._id}`)
        window.scrollTo(0, 0);
    }

    const toDistributorPage = (event) => {
        history.push(`/distributor/${product.user._id}`)
        window.scrollTo(0, 0);
    }

    const editProductOnClick = (event) => {
        history.push(`/myproducts/edit/${product._id}`)
        //window.scrollTo(0, 0);
    }

    const handleCloseSuccessSnack = () => {
        setOpenSuccessSnack(false)
    }

    const handleAvailableChange = async (event) => {
        if(availableLoading) return;
        setAvailableChecked(event.target.checked)
        setAvailableLoading(true)
        try{
             
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' ,
                            'Authorization': `${accessToken}`},
                body: JSON.stringify({
                    _id : product._id,
                    available: true, 
                    availableChecked: event.target.checked
                })
            };
            let result = await fetch(`${url}/api/productsImport/`, requestOptions)
            if(result.status === 200){
                setOpenSuccessSnack(true)
                setAvailableLoading(false)
            }
            else{
                setAvailableChecked(!(event.target.checked))
                setOpenDeleteApiErrorSnack(true)
                setAvailableLoading(false)
            }
        }   
        catch(e){
            setAvailableChecked(!(event.target.checked))
            setOpenDeleteApiErrorSnack(true)
            setAvailableLoading(false)
        }
    }



    const calculateSubTotal = (count) => {
        let productPrice = product.price + (product.fastDelivery? calcSendingCost(product) : 0)
        let productImport = product.fastDelivery? 0 : calcSendingCost(product)
        return reduceDecimalValue((productPrice+productImport)*count, 2)
    }

    const getAvailableText = (size, color) => {
        if(sizes.length > 0 && productColors.length > 0){
            if(sizeSelected && colorSelected && size && color){
                for(const t of product.availableCountByTags){
                    if(t.size === size && t.color === color){
                        if(product.fastDelivery) return `DISPONIBLE: ${t.availableCount}`
                        else return `CANT.MAX: ${t.availableCount}`
                    }
                }
            }
        }
        if(product.fastDelivery) return `DISPONIBLE: ${product.availableCount}`
        else return `CANT.MAX: ${product.maxCount}`
    }

    const handleChangeColor = (newColor) => {
        if(sizes.length > 0 && productColors.length > 0){
            if(sizeSelected && colorSelected && newColor){
                for(const t of product.availableCountByTags){
                    if(t.size === sizeSelected && t.color === newColor){
                        if(newColor !== colorSelected) setColorSelected(newColor)
                        return
                    }
                }
                for(const t of product.availableCountByTags){
                    if(t.color === newColor && t.availableCount > 0){
                        setSizeSelected(t.size)
                        if(newColor !== colorSelected) setColorSelected(newColor)
                        return
                    }
                }
            }
        }
        if(newColor !== colorSelected) setColorSelected(newColor)
    }

    const handleChangeSize = (newSize) => {
        if(sizes.length > 0 && productColors.length > 0){
            if(sizeSelected && colorSelected && newSize){
                for(const t of product.availableCountByTags){
                    if(t.size === newSize && t.color === colorSelected){
                        if(newSize !== sizeSelected) setSizeSelected(newSize)
                        return
                    }
                }
                for(const t of product.availableCountByTags){
                    if(t.size === newSize && t.availableCount > 0){
                        setColorSelected(t.color)
                        if(newSize !== sizeSelected) setSizeSelected(newSize)
                        return
                    }
                }
            }
        }
        if(newSize !== sizeSelected) setSizeSelected(newSize)
    }

    const existSizeColor = (size, color, element) => {
        if(element === size || element === color) {
            console.log("ENTRO")
            return true
        }
        if(sizes.length > 0 && productColors.length > 0){
            if(sizeSelected && colorSelected && size && color){
                for(const t of product.availableCountByTags){
                    if(t.size === size && t.color === element){
                        return true
                    }
                }
            }
        }
        return false
    }

    return (
        <Card
            elevation={detailPage? 0 : 3} 
            className={detailPage? classes.productCardDetail : classes.productCard}
        >
            
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="next order state"
                aria-describedby="data necessary to process to next state"
            >
                <div className={classes.modalPaper}>
                    <div style={{display: 'flex', marginBottom: '5px', alignContent: 'top'}}> 
                        <div>
                            <Typography className={classes.mainText}>{product.name.toUpperCase()}</Typography>
                            <LinesEllipsis 
                                className={classes.description}
                                text={product.description.toUpperCase()}
                                maxLine='5'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                            />
                        </div>
                        <IconButton style={{marginLeft: 'auto', height: '48px', width: '48px'}} onClick={() => {setOpenModal(false)
                                                    setCartCount(1)}} >
                            <Cancel />
                        </IconButton>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{marginTop: '10px', paddingLeft: '0px', paddingRight: '10px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <AttachMoney className={classes.leftInfoIconFilled}/>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '20px'}}>PRECIO</Typography>
                                </div>
                                <Typography className={classes.rightInfoTextDetailPage}>{`${reduceDecimalValue(product.price + calcSendingCost(product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                            </div>
                        </div>
                        <div style={{marginLeft: 'auto', alignItems: 'end', justifyContent: 'end', display: 'flex', flexDirection: 'column'}}>
                            <Typography style={{fontWeight: '800', lineHeight: '16px'}}>SUBTOTAL</Typography>
                            <Typography style={{fontWeight: '600'}}>{calculateSubTotal(cartCount)} USD</Typography>
                        </div>
                    </div>
                    {product?.deliveryCalendar?.allowed &&
                        <div>
                            <PickCalendarProduct  
                                yearsMonthsDaysAvailable={yearsMonthsDaysAvailable} 
                                selectedDate={selectedDate}
                                newDateSetter={setSelectedDate}
                                formErrorText={deliveryCalendarErrorText}
                                formErrorTextSetter={setDeliveryCalendarErrorText} 

                            />
                        </div>
                    }
                    {hasSizeAndColor(product.category._id) &&
                    <div>
                        <div style={{marginTop: '10px', paddingLeft: '0px', paddingRight: '10px'}}>
                            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '5px'}}>TALLA:</Typography>
                                </div>
                                {sizes.map((element, index) => {
                                    return(
                                        <div
                                            onClick={() => {
                                                handleChangeSize(element)
                                            }}
                                            key={index}
                                            style={
                                                {   
                                                    backgroundColor: element, 
                                                    paddingLeft: '8px',
                                                    paddingRight: '8px',
                                                    borderRadius: '5px', 
                                                    marginRight: '7px', 
                                                    marginTop: '4px',
                                                    borderStyle: 'solid',
                                                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                                    borderWidth: '2.5px',
                                                    cursor: 'pointer',
                                                    borderColor: element === sizeSelected? colors.primary : 'transparent',
                                                }
                                            } 
                                            >
                                            <Typography style={{fontSize: element === sizeSelected? '18px' : '16px'}}>
                                                {element}
                                            </Typography>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{marginTop: '10px', paddingLeft: '0px', paddingRight: '10px'}}>
                            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '5px'}}>COLOR:</Typography>
                                </div>
                                {productColors.map((element, index) => {
                                    return(
                                        <div
                                            onClick={() => {
                                                handleChangeColor(element)
                                            }}
                                            key={index}
                                            style={
                                                {   backgroundColor: element, 
                                                    width: element === colorSelected? '25px' : '20px', 
                                                    height: element === colorSelected? '25px' : '20px',
                                                    cursor: 'pointer', 
                                                    borderRadius: '5px', 
                                                    marginRight: '7px', 
                                                    marginTop: '4px',
                                                    borderColor: element === colorSelected? colors.primary : 'transparent',
                                                    borderStyle: 'solid',
                                                    opacity: existSizeColor(sizeSelected, colorSelected, element)? '1' : '0.4',
                                                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                                    borderWidth: '2.5px'
                                                }
                                            } 
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    }
                    <div style={{display:'flex', justifyContent: 'space-between', marginBottom: '5px', marginTop: '15px'}}>
                        <div>
                            <Typography style={{fontWeight: '600', fontSize: '13px', textAlign: 'center'}}>{getAvailableText(sizeSelected, colorSelected)}</Typography>
                            {(!product.fastDelivery && product.availableCount < product.maxCount)&& <Typography style={{fontWeight: '600', fontSize: '13px', textAlign: 'center'}}>DISPONIBLE: {product.availableCount}</Typography>}
                            <div style={{display: 'flex', height: '25px'}}>
                                <Typography onClick={removeFromCart} className={classes.minusCart}>-</Typography>
                                <Typography className={classes.cartCount}>{cartCount}</Typography>
                                <Typography onClick={addToCart} className={classes.sumCart}>+</Typography>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {updatingCart?
                                <Button variant='outlined' className={classes.addCartButton}>
                                    <CircularProgress style={{width: '20px', height: '20px', color: 'white'}}/>   
                                </Button>
                                :
                                <Button disabled={updatingCart} onClick={() => {onClickAddConfirm(false)}} variant='outlined' className={classes.addCartButton}>
                                    Agregar al <ShoppingCartOutlined style={{color: 'white'}}/>
                                </Button>
                            }
                            {updatingCart?
                                <Button variant='outlined' className={classes.buyButton}>
                                    <CircularProgress style={{width: '20px', height: '20px', color: 'white'}}/>   
                                </Button>
                                :
                                <Button disabled={updatingCart} onClick={() => {onClickAddConfirm(true)}} variant='outlined' className={classes.buyButton}>
                                    COMPRAR <Shop style={{color: 'white', marginLeft: '2px'}}/>
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            
            <CardActionArea>
                <Snackbar open={openCartSnack} autoHideDuration={5000} onClose={handleCloseCartSnack}>
                    <Alert onClose={handleCloseCartSnack} severity="warning">
                        {cartText}
                    </Alert>
                </Snackbar>
                <Snackbar open={openCartSuccessSnack} autoHideDuration={1500} onClose={handleCloseSuccessCartSnack}>
                  <Alert onClose={handleCloseSuccessCartSnack} severity="success">
                      Producto añadido al carrito
                  </Alert>
                </Snackbar>
                <Snackbar open={openCartErrorSnack} autoHideDuration={5000} onClose={handleCloseCartErrorSnack}>
                  <Alert onClose={handleCloseCartErrorSnack} severity="error">
                      No se pudo añadir el producto al carrito. Revise su conexión a internet. Si el problema persiste puede añadir
                      el producto a favoritos para añadirlo al carrito más tarde.
                  </Alert>
                </Snackbar>
                <Snackbar open={openSuccessSnack} autoHideDuration={2000} onClose={handleCloseSuccessSnack}>
                    <Alert onClose={handleCloseSuccessSnack} severity="success">
                        Disponibilidad actualizada correctamente.
                    </Alert>
                </Snackbar>
                <Snackbar open={openLoginNeededSnack} autoHideDuration={2000} onClose={handleCloseLoginNeededSnack}>
                    <Alert onClose={handleCloseLoginNeededSnack} severity="warning">
                        Debe iniciar sesión para realizar esta accion.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteSuccessSnack} autoHideDuration={1000} onClose={handleCloseDeleteSuccessSnack}>
                    <Alert onClose={handleCloseDeleteSuccessSnack} severity="success">
                        Se eliminó el anuncio.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteApiErrorSnack} autoHideDuration={2000} onClose={handleCloseDeleteApiErrorSnack}>
                    <Alert onClose={handleCloseDeleteApiErrorSnack} severity="error">
                        No se pudo completar la acción. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <div style={{position: 'relative'}} onClick={productOnClick}>
                    {!detailPage && product.promoted &&
                        <div className={classes.productPromotedTag}>
                            {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                            <Stars className={classes.fastDeliveryIcon}/>
                            <Typography className={classes.fastDeliveryText}>PROMO</Typography>
                        </div>
                    }
                    {!detailPage &&
                    <CardMedia>
                        {loaded?
                            <LazyLoadImage
                            className={classes.media}
                            alt={product._id}
                            placeholderSrc={placeholder}
                            visibleByDefault={true}
                            effect="blur"
                            src={product.mainImageUrl} />
                        :
                            <LazyLoadImage
                            className={classes.mediaPlaceholder}
                            placeholderSrc={placeholder}
                            alt={product._id}
                            effect="blur"
                            afterLoad = {mediaDisplay}
                            src={product.mainImageUrl} />
                        }
                    </CardMedia>
                    }
                    <CardContent 
                        style={{paddingLeft: '0px', paddingRight: '0px', paddingBottom: '15px'}}
                    >
                        {!detailPage &&
                        <LinesEllipsis 
                            className={classes.title}
                            text={product.name.toUpperCase()}
                            maxLine='5'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                        }
                        {(userId === -1 && product.available === false) &&
                            <Typography className={classes.title} style={{color: colors.orangeEdit}}>
                                No disponible por el momento
                            </Typography>
                        }
                        
                        {/* {!detailPage && product.applyDiscount &&
                            <div className={classes.possibleDiscountTag}>
                                <LocalOffer className={classes.fastDeliveryIcon}/>
                                <Typography className={classes.fastDeliveryText}>APLICA DESCUENTO POR CUPON</Typography>
                            </div>
                        } */}
                        
                        {(!product.available || product.availableCount <= 0) &&
                                <div className={detailPage? classes.unavailableTagDetailPage : classes.unavailableTag}>
                                    <Close className={classes.fastDeliveryIcon}/>
                                    <Typography className={classes.fastDeliveryText}>AGOTADO</Typography>
                                </div>
                        }

                        {!detailPage && !product.fastDelivery &&
                            <div className={product.shippingBySea? classes.productImportBySeaTag : classes.productImportTag}>
                                {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                                {product.shippingBySea?
                                    <DirectionsBoat className={classes.fastDeliveryIcon}/>
                                    :
                                    <Flight className={classes.fastDeliveryIcon} style={{transform: 'rotate(45deg)'}}/>
                                }
                                <Typography className={classes.fastDeliveryText}>IMPORTACION</Typography>
                            </div>
                        }
                        
                        {!detailPage && product.fastDelivery &&
                            <div className={classes.productFastDeliveryTag}>
                                {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                                <LocalShipping className={classes.fastDeliveryIcon}/>
                                <Typography className={classes.fastDeliveryText}>ENTREGA INMEDIATA</Typography>
                            </div>
                        }
                        <div style={{marginTop: detailPage? '-7px': '10px', paddingLeft: '10px', paddingRight: '10px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display :'flex', alignItems: 'center'}}>
                                    <AttachMoney className={classes.leftInfoIconFilled}/>
                                    <Typography className={classes.leftInfoTag} style={{marginRight: '20px'}}>PRECIO</Typography>
                                </div>
                                <Typography className={detailPage? classes.rightInfoTextDetailPage : classes.rightInfoText}>{`${reduceDecimalValue(product.price + calcSendingCost(product))} `}<span className={classes.currencyPrice}>USD</span></Typography>
                            </div>
                            {detailPage &&
                            <div style={{marginBottom: "-8px"}}>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                    <div style={{display :'flex', alignItems: 'center'}}>
                                        <Visibility className={classes.leftInfoIcon}/>
                                        <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>VISTAS</Typography>
                                    </div>
                                    <Typography className={detailPage? classes.rightInfoTextDetailPage : classes.rightInfoText}>{product.viewsCount}</Typography>
                                </div>
                                {!product.fastDelivery &&
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                    <div style={{display :'flex', alignItems: 'center'}}>
                                        <FitnessCenter className={classes.leftInfoIcon}/>
                                        <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>PESO</Typography>
                                    </div>
                                    <Typography className={detailPage? classes.rightInfoTextDetailPage : classes.rightInfoText}>{reduceDecimalValue(product.weight, 2)}<span className={classes.currencyPrice}> KG</span></Typography>
                                </div>
                                }
                                {(product.available && product.availableCount > 0) &&
                                    <React.Fragment>
                                        {product.fastDelivery?
                                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                                <div style={{display :'flex', alignItems: 'center'}}>
                                                    <VerticalAlignTop className={classes.leftInfoIcon}/>
                                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>DISPONIBLES</Typography>
                                                </div>
                                                <Typography className={detailPage? classes.rightInfoTextDetailPage : classes.rightInfoText}>{product.availableCount}</Typography>
                                            </div>
                                        :
                                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                                <div style={{display :'flex', alignItems: 'center'}}>
                                                    <VerticalAlignTop className={classes.leftInfoIcon}/>
                                                    <Typography className={classes.leftInfoTag}  style={{marginRight: '16px'}}>CANT MAX ENTREGA</Typography>
                                                </div>
                                                <Typography className={detailPage? classes.rightInfoTextDetailPage : classes.rightInfoText}>{product.maxCount}</Typography>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            }
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '2px'}}>
                                <Typography className={detailPage? classes.deliveryTimeTextDetailPage : classes.deliveryTimeText}>ENT APROX: {getDeliveryTime(product)}</Typography>
                            </div>
                        </div>
                    </CardContent>
                </div>
            </CardActionArea>
            <CardActions style={{display: 'block'}} disableSpacing>
            {userLogged?._id === product.user._id?
            <React.Fragment>
                <DeleteProductModal openDeleteModal={openDeleteModal} productId={product._id} productName={product.name} setOpenSuccesSnack={OpenDeleteSuccessSnack} setOpenApiErrorSnack={OpenDeleteApiErrorSnack} closeDeleteModalParent={closeDeleteModal}/>
                {!homePage &&
                    <div style={{display: 'flex', marginBottom: '5px'}}>
                        <Typography style={{paddingTop: '5px', marginLeft: '10px', marginRight: 'auto'}}>Disponible</Typography>
                        {availableLoading && 
                            <CircularProgress style={{height:'20px', width:'20px', marginTop:'10px', marginLeft:'9px'}}/>
                        }
                        <Switch
                            checked={availableChecked}
                            onChange={handleAvailableChange}
                            color="primary"
                            name="availableChecked"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                    }
                <Grid container alignItems="center" justify={"space-around"} className={classes.group} style={{borderColor: detailPage? colors.whiteAppbar : '#E6E6E6', borderWidth: detailPage? '2.5px' : '0.45px'}}>
                    <Grid item>
                        <IconButton
                            className={classes.editIcon}
                            aria-label="edit"
                            onClick={editProductOnClick}>
                            <EditIcon  />
                        </IconButton>
                    </Grid>
                    {(!homePage && userLogged?.isSuperAdmin) &&
                        <Grid item>
                            <IconButton
                                className={classes.deleteIcon}
                                aria-label="delete"
                                onClick={(event) => {setOpenDeleteModal(true)}}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    }
                    {(product.available && product.availableCount > 0) &&
                    <Grid item>
                        <IconButton onClick={onClickAddToCart} className={classes.addCart} aria-label="add Cart">
                            <AddShoppingCartIcon style={{height: '30px', width: '30px'}} />
                        </IconButton>
                    </Grid>
                    }
                    <Grid item>
                        <RWebShare
                            data={{
                                url: `${appShareUrl}/products/detail/${product._id}`,
                                title: "Sevende Import",
                            }}
                            closeText="Cerrar"
                        >
                            <IconButton className={classes.share} aria-label="share">
                                <ShareOutlinedIcon />
                            </IconButton>
                        </RWebShare>
                    </Grid>
                </Grid>
            </React.Fragment>
            :
                <div style={{display: 'flex', alignItems: 'end'}}>
                    {!detailPage && 
                        <div onClick={toDistributorPage} style={{cursor: 'pointer'}}>
                            <img src={product.user.profilePictureUrl? product.user.profilePictureUrl : userPlaceholder} className={classes.userProduct}/>
                        </div>
                    }
                    <Grid container alignItems='center' className={classes.group}  style={{borderColor: detailPage? colors.whiteAppbar : '#E6E6E6', borderWidth: detailPage? '2.5px' : '0.45px'}}>
                        {/* <Grid item>
                            {isProductFavorite?
                            <IconButton
                                className={classes.favoriteOn}
                                onClick={RemoveFromFavorite}
                                aria-label="remove from favorites">
                                    <FavoriteIcon  />
                            </IconButton>
                            :
                            <IconButton
                                className={classes.favorite}
                                onClick={AddToFavorite}
                                aria-label="add to favorites">
                                    <FavoriteBorderOutlinedIcon  />
                            </IconButton>
                            }
                        </Grid> */}
                        
                        <Grid item>
                            <RWebShare
                                data={{
                                url: `${appShareUrl}/products/detail/${product._id}`,
                                title: "Compartir Producto"
                            }}
                            closeText="Cerrar"
                            >
                                <IconButton className={classes.share} aria-label="share">
                                    <ShareOutlinedIcon />
                                </IconButton>
                            </RWebShare>
                        </Grid>
                    </Grid>
                    {(product.available && product.availableCount > 0) &&
                        <IconButton onClick={onClickAddToCart} className={classes.addCart} aria-label="add Cart">
                            <AddShoppingCartIcon style={{height: '36px', width: '36px'}} />
                        </IconButton>
                    }
                </div>
            }
            </CardActions>
            {detailPage &&
                <CardActionArea>
                    {(product.promoted || product.possibleDiscount || product.fastDelivery) &&
                    <div style={{width: '100%', backgroundColor: colors.whiteAppbar, height: '2.5px', marginTop: '-2px', marginBottom: '10px'}}/>
                    }
                    {product.promoted &&
                        <div className={classes.productPromotedTagDetailPage}>
                            {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                            <Stars className={classes.leftInfoIcon}/>
                            <Typography className={classes.leftInfoTag}>PROMO</Typography>
                        </div>
                    }
                    {product.possibleDiscount &&
                        <div className={classes.productPromotedTagDetailPage}>
                            {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                            <LocalOffer className={classes.leftInfoIcon}/>
                            <Typography className={classes.leftInfoTag}>POSIBLE DESCUENTO</Typography>
                        </div>
                    }
                    {product.fastDelivery &&
                        <div className={classes.productPromotedTagDetailPage}>
                            {/* <img src={b2_truck} className={classes.fastDeliveryIcon} /> */}
                            <LocalShipping className={classes.leftInfoIcon} style={{marginLeft: '2px'}}/>
                            <Typography className={classes.leftInfoTag}>ENTREGA INMEDIATA</Typography>
                        </div>
                    }
                    {(product.promoted || product.possibleDiscount || product.fastDelivery) &&
                    <div style={{width: '100%', height: '8px'}}/>
                    }
                </CardActionArea>
            }
        </Card>
    );
}