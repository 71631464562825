import { IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import b2_truck from '../../../assets/promotional_banner/b2_truck.png'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import url, { colors, sendingCostValue } from "../../../config";
import LinesEllipsis from "react-lines-ellipsis";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import ProductCard from "../../global/ProductCard";
import SkeletonCard from "../../global/SkeletonCard";
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
    externalHolder: {
        background: `linear-gradient(to right, ${colors.topSellHomePage} 60%, ${colors.topSellHomePage} 100%)`,
        marginTop: '15px',
        paddingBottom: '0px',
    },
    topSellText: {
        fontSize: '58px',
        fontWeight: '800',
        textAlign: 'center',
        fontFamily: '"Nerko One", cursive !important',
        color: colors.whiteAppbar,
        [theme.breakpoints.down('lg')]: {
            fontSize: '42px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '36px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '26px',
            paddingBottom: '5px'
        }
    },
    bannerContainerLayoutLg: {
        [theme.breakpoints.up('lg')]: {
            width: '1140px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    bannerContainerLayoutSm: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    bannerContainerLayoutXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'inherit',
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    carouselElement: {
        display: 'flex', 
        paddingBottom: '40px',
        paddingLeft: '10px',
        paddingRight: '10px', 
        
    },
    productElement: {
        width: '208px',
        [theme.breakpoints.down('lg')]: {
            flex: 0.18,
        },
        [theme.breakpoints.down('md')]: {
            flex: 0.23,
        },
        [theme.breakpoints.down('sm')]: {
            flex: 0.31,
        },
        [theme.breakpoints.down('xs')]: {
            flex: 0.48,
        },
    },
    topTriangle: {
        width: '100%',
        height: '50px',
        backgroundImage:
            `linear-gradient(to bottom right, ${colors.whiteAppbar} 50%, ${colors.topSellHomePage} 51%, ${colors.topSellHomePage} 100%)`,
        [theme.breakpoints.down('sm')]: {
            height: '25px'
        }
    },
    topTriangleBottom: {
        width: '100%',
        height: '50px',
        backgroundImage:
        `linear-gradient(to bottom right, ${colors.topSellHomePage} 50%, ${colors.whiteAppbar} 51%, ${colors.whiteAppbar} 100%)`,
        [theme.breakpoints.down('sm')]: {
            height: '25px'
        }
    }
}))

const skeletons = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
]

const TopSellBanner = ({region}) => {
    const classes = useStyles()
    const [products, setProducts] = useState([])

    const [size, setSize] = useState(0)

    useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    useEffect(() => {
        const abort = {current: false}
        const fetchTopSellProducts = async () => {
            try{
                const requestOptions = {
                method: 'GET',
                headers: { 
                        'Content-Type': 'application/json',
                    }
                }
                console.log(region)
                let result = await fetch(`${url}/api/productsImport/?page=1&limit=15&topSellProducts=true&region=${region}`, requestOptions)
                if(result.status === 200){
                    let dataResult = await result.json()
                    if(!abort.current) setProducts(dataResult)
                }
                else{
                    console.log("error")
                }
                
            } catch(error){
                console.log(error)
            }
        }
        abort.current = false
        fetchTopSellProducts()
        return () => {
            abort.current = true
        }
    }, [region])

    const getProducts = (index, widthSize) => {
        console.log(products.length)
        if(widthSize < 640){
            if(index === 0) return [products[0], products[1]]
            if(index === 1) return [products[2], products[3]]
            if(index === 2) return [products[4], products[5]]
            if(index === 3) return [products[6], products[7]]
            if(index === 4) return [products[8], products[9]]
        }
        if(widthSize < 900){
            if(index === 0) return [products[0], products[1], products[2]]
            if(index === 1) return [products[3], products[4], products[5]]
            if(index === 2) return [products[6], products[7], products[8]]
            if(index === 3) return [products[9], products[10], products[11]]
            if(index === 4) return []
        }
        if(widthSize < 1140){
            if(index === 0) return [products[0], products[1], products[2], products[3]]
            if(index === 1) return [products[4], products[5], products[6], products[7]]
            if(index === 2) return [products[8], products[9], products[10], products[11]]
            if(index === 3) return []
            if(index === 4) return []
        }
        if(index === 0) return [products[0], products[1], products[2], products[3], products[4]]
        if(index === 1) return [products[5], products[6], products[7], products[8], products[9]]
        if(index === 2) return [products[10], products[11], products[12], products[13], products[14]]
        if(index === 3) return []
        if(index === 4) return []
    }

    const getProductsSkeleton = (index, widthSize) => {
        if(widthSize < 640){
            if(index === 0) return [skeletons[0], skeletons[1]]
            if(index === 1) return [skeletons[2], skeletons[3]]
            if(index === 2) return [skeletons[4], skeletons[5]]
            if(index === 3) return [skeletons[6], skeletons[7]]
            if(index === 4) return [skeletons[8], skeletons[9]]
        }
        if(widthSize < 900){
            if(index === 0) return [skeletons[0], skeletons[1], skeletons[2]]
            if(index === 1) return [skeletons[3], skeletons[4], skeletons[5]]
            if(index === 2) return [skeletons[6], skeletons[7], skeletons[8]]
            if(index === 3) return [skeletons[9], skeletons[10], skeletons[11]]
            if(index === 4) return []
        }
        if(widthSize < 1140){
            if(index === 0) return [skeletons[0], skeletons[1], skeletons[2], skeletons[3]]
            if(index === 1) return [skeletons[4], skeletons[5], skeletons[6], skeletons[7]]
            if(index === 2) return [skeletons[8], skeletons[9], skeletons[10], skeletons[11]]
            if(index === 3) return []
            if(index === 4) return []
        }
        if(index === 0) return [skeletons[0], skeletons[1], skeletons[2], skeletons[3], skeletons[4]]
        if(index === 1) return [skeletons[5], skeletons[6], skeletons[7], skeletons[8], skeletons[9]]
        if(index === 2) return [skeletons[10], skeletons[11], skeletons[12], skeletons[13], skeletons[14]]
        if(index === 3) return []
        if(index === 4) return []
    }


    return (
        <div className={classes.externalHolder}>
            <div className={classes.topTriangle} />
            {products.length > 0 ?
            <React.Fragment>
            <div className={`${classes.bannerContainerLayoutLg} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '12px', height: '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(0, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(1, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(2, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className={`${classes.bannerContainerLayoutSm} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '10px', height: '10px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(0, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(1, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(2, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(3, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className={`${classes.bannerContainerLayoutXs} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '10px', height: '10px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(0, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(1, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(2, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(3, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProducts(4, size).map((product, index) => {
                                return(
                                        <div className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <ProductCard product={product} homePage={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            </React.Fragment>
            :
            <React.Fragment>
            <div className={`${classes.bannerContainerLayoutLg} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '12px', height: '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(0, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(1, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(2, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className={`${classes.bannerContainerLayoutSm} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '12px', height: '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(0, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(1, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(2, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(3, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className={`${classes.bannerContainerLayoutXs} banner-top-sell`}>
                <Typography className={classes.topSellText}>LO MAS VENDIDO DE LA SEMANA</Typography>
                <Carousel
                    autoPlay={false} 
                    interval={5000} 
                    showStatus={false} 
                    showIndicators={true} 
                    showThumbs={false} 
                    showArrows={false}
                    useKeyboardArrows
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={10}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const defStyle = { marginLeft: '15px', backgroundColor: colors.lightGrayLogo, cursor: "pointer", width: '12px', height: '12px', borderRadius: '50%' };
                        const style = isSelected
                            ? { ...defStyle, backgroundColor: colors.whiteAppbar }
                            : { ...defStyle };
                        return (
                            <div
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                            </div>
                        );
                    }}
                    infiniteLoop={false}
                >
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(0, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(1, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(2, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(3, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div className={classes.carouselElement}>
                            {getProductsSkeleton(4, size).map((product, index) => {
                                return(
                                        <div key={product} className={classes.productElement} style={{marginLeft: index!==0? 'auto' : '0px'}}>
                                            <SkeletonCard />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Carousel>
            </div>
            </React.Fragment>
            }
            <div className={classes.topTriangleBottom} />

        </div>
    )
}

export default TopSellBanner